<script>
import CardLeft from "./CardLeft.vue";
import CardRight from "./CardRight.vue";
import { mapState } from "vuex";

export default {
  components: { CardLeft, CardRight },

  props: {
    chatItem: {
      type: Object,
      default: () => {},
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    isCurrentUser() {
      return this.chatItem.createdBy === this.$store.state.session.email;
    },

    isForCurrentUser() {
      return this.chatItem.for === this.$store.state.session.email;
    },

    getPriorityColor() {
      if (this.chatItem.workflow.priority === "Low") {
        return this.darkTheme ? "text-green-5" : "text-green-6";
      }

      if (this.chatItem.workflow.priority === "Medium") {
        return this.darkTheme ? "text-orange-5" : "text-orange-6";
      }

      if (this.chatItem.workflow.priority === "High") {
        return this.darkTheme ? "text-red-5" : "text-red-6";
      }

      return this.darkTheme ? "text-gray-6" : "text-gray-5";
    },
  },
};
</script>

<template>
  <component
    :is="isCurrentUser ? 'CardRight' : 'CardLeft'"
    :created-by="chatItem.createdBy"
    :created-at="chatItem.createdAt"
    :hide-avatar="hideAvatar"
  >
    <div id="workflow-card">
      <div class="content-wrapper">
        <div class="icon">
          <BaseIcon name="account_tree" color="secondary" />
        </div>

        <div class="content">
          <div class="name">{{ chatItem.workflowName }}</div>

          <div class="meta">
            <div class="wrapper">
              <div class="key">Req No:</div>
              <div class="value">
                {{ chatItem.requestNo }}
              </div>
            </div>

            <div class="wrapper">
              <div class="key">Status:</div>
              <div class="value">
                {{ chatItem["Flow Status"] }}
              </div>
            </div>

            <div class="wrapper">
              <div class="key">Comments:</div>
              <div class="value">
                {{ chatItem.commentsCount }}
              </div>
            </div>

            <div class="wrapper">
              <div class="key">Attachments:</div>
              <div class="value">
                {{ chatItem.attachmentsCount }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
#workflow-card {
  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 0px;

    .content {
      .name {
        font-weight: 600;
        margin-bottom: 4px;
      }

      .meta {
        display: flex;
        align-items: center;
        gap: 16px;

        .wrapper {
          display: flex;
          gap: 8px;
          font-size: 11px;
          color: var(--icon-color-inverted);

          .value {
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
