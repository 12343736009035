<script>
import FileIcon from "@/components/common/FileIcon.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import FileActions from "./FileActions.vue";
import { workspaceNew } from "@/api/factory.js";
import formatBytes from "@/helpers/format-bytes.js";
import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet.vue";

export default {
  name: "FileList",

  components: { FileIcon, Pagination, FileActions, FileSheet },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "name",
        },
        {
          id: this.$nano.id(),
          name: "size",
          label: "size",
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "created by",
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "created at",
        },
      ],
      files: [
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8001.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:27 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8002.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:30 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8003.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:33 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8004.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:36 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8005.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:39 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8006.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:42 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8007.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:45 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8008.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:48 PM",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "Inv-8009.pdf",
        //   size: "728.39 KB",
        //   createdBy: "seth@ezofis.com",
        //   createdAt: "09-Jun-2024 05:51 PM",
        // },
      ],
      totalItems: 5,
      itemsPerPage: 50,
      currentPage: 1,
      selectedFile: {},
      showFileSheet: false,
    };
  },

  watch: {
    selectedWorkspace: {
      handler() {
        this.files = [];
        this.currentPage = 1;
        this.getFileList();
      },
      immediate: true,
    },
  },

  methods: {
    async getFileList() {
      const { error, payload } = await workspaceNew.getFileList(
        this.selectedWorkspace.id,
        this.currentPage,
        this.itemsPerPage
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }

      const { meta, data } = payload;
      console.log("🚀 ~ getFileList ~ data:", data);
      this.totalItems = meta.totalItems || 0;
      this.files.push(...data[0].value);
    },

    fileSize(size) {
      return formatBytes(size);
    },

    viewFile(file) {
      this.selectedFile = file;
      this.showFileSheet = true;
    },
  },
};
</script>

<template>
  <div id="files">
    <div class="table-wrapper">
      <BaseScrollbar
        :height="`calc(100vh - ${$q.fullscreen.isActive ? '190px' : '381px'})`"
        class="q-pa-md"
      >
        <table>
          <thead>
            <tr>
              <th class="actions"></th>
              <th v-for="column in columns" :key="column.id">
                {{ column.label }}
              </th>
              <th class="actions"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="file in files" :key="file.id">
              <td>
                <FileIcon :mime-type="file.file.type" />
              </td>
              <td class="name" @click="viewFile(file)">{{ file.file.name }}</td>
              <td>{{ fileSize(file.file.size) }}</td>
              <td>{{ file.createdBy }}</td>
              <td>{{ $day.format(file.createdAt) }}</td>
              <td>
                <FileActions :file="file.file" />
              </td>
            </tr>
          </tbody>
        </table>

        <FileSheet
          v-if="showFileSheet"
          v-model="showFileSheet"
          :file="{
            id: selectedFile.file.itemId,
            name: selectedFile.file.name,
            size: selectedFile.file.size,
            repositoryId: selectedFile.file.repositoryId,
          }"
          :repository-field="[]"
          :options="{}"
        />
      </BaseScrollbar>
    </div>

    <div class="pagination-wrapper">
      <Pagination
        no-border
        :total-items="totalItems"
        :current-page.sync="currentPage"
        :items-per-page.sync="itemsPerPage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#files {
  .table-wrapper {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    margin: 16px 0px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--body-bg-color);

    th {
      color: var(--title-1-color);
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
      padding: 12px 8px;
      border-bottom: 1px solid var(--body-bg-color);
      background-color: var(--body-bg-color-light);

      &.actions {
        width: 48px;
      }
    }

    td {
      padding: 8px;
      border-bottom: 1px solid var(--body-bg-color);

      &.name {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .pagination-wrapper {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    padding: 16px;
  }
}
</style>
