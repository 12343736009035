<script>
import CardLeft from "./CardLeft.vue";
import CardRight from "./CardRight.vue";

export default {
  components: { CardLeft, CardRight },

  props: {
    chatItem: {
      type: Object,
      default: () => {},
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isCurrentUser() {
      return this.chatItem.createdBy === this.$store.state.session.email;
    },
  },
};
</script>

<template>
  <component
    :is="isCurrentUser ? 'CardRight' : 'CardLeft'"
    :created-by="chatItem.createdBy"
    :created-at="chatItem.createdAt"
    :hide-avatar="hideAvatar"
    :is-deleted="1"
  >
    <q-spinner color="secondary" size="36px" />
  </component>
</template>

<style lang="scss" scoped></style>
