<script>
import { VEmojiPicker } from "v-emoji-picker";

export default {
  name: "EmojiPicker",

  components: { VEmojiPicker },

  methods: {
    handleSelect(emoji) {
      this.$emit("select", emoji.data);
    },
  },
};
</script>

<template>
  <BaseActionButton is-flat icon="mdi-emoticon-outline" no-border>
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="top left"
      self="bottom start"
      :offset="[0, 16]"
      class="q-pa-sm"
    >
      <VEmojiPicker :except-categories="['Flags']" @select="handleSelect" />
    </q-menu>
  </BaseActionButton>
</template>

<style lang="scss">
.emoji-picker {
  background-color: var(--component-bg-color) !important;
  border: none !important;
  padding: 8px !important;
  width: 340px !important;

  #Categories {
    border: none !important;
    margin-bottom: 8px !important;
    border-radius: 4px !important;
    background: var(--body-bg-color) !important;
  }

  .category {
    padding: 10px 6px 4px !important;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent !important;
    filter: none !important;

    svg {
      fill: var(--icon-color) !important;

      &:hover {
        fill: var(--secondary) !important;
      }
    }

    &.active {
      border-bottom: 2px solid var(--secondary) !important;

      svg {
        fill: var(--secondary) !important;
      }
    }
  }

  .container-search {
    margin: 0 !important;
    padding: 0 !important;

    input {
      border: 1px solid var(--border-color) !important;
      border-radius: 4px !important;
      background-color: transparent !important;
      height: 36px !important;
    }
  }
}
</style>
