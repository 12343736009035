<template>
  <div>
    <TaskFormListModal
      v-if="showTaskFormListModal"
      v-model="showTaskFormListModal"
      :task-form-list="taskFormList"
      @close="closeTaskListModal"
      @selectTaskForm="getTaskFormId"
    />

    <AddTask
      v-if="isAddTaskVisible"
      v-model="isAddTaskVisible"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="formId"
      :selected-task="selectedTask"
      :repository-id="repositoryId"
      :task-model="selectedTask"
      :mode="taskMode"
      :task-name="taskName"
      :task-workflow-list="taskWorkflowList"
      @history="showHistory"
      @close="closeAddTaskModal"
      @refresh="refresh()"
      @add="(payload) => $emit('add', payload)"
    />
  </div>
</template>
<script>
import { form, workflow } from "@/api/factory.js";
import TaskFormListModal from "@/views/tasks/views/tasks-browse-and-trash/components/TaskFormListModal.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask.vue";
import DataType from "@/constants/data-type.js";

export default {
  name: "AddTaskNew",

  components: {
    TaskFormListModal,
    AddTask,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    showAddTask: {
      type: Boolean,
      default: false,
    },

    taskMode: {
      type: String,
      default: "",
    },

    taskId: {
      type: [String, Number],
      default: "",
    },

    formEntryId: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "type",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      workflowList: [],
      taskWorkflowList: [],
      selectedTask: {},
      processInfo: [],
      workflowCount: 0,
      workflowFormTaskList: {},
      taskFormList: [],
      formId: 0,
      repositoryId: 0,
      addTaskForm: [],
      showTaskFormListModal: false,
      isAddTaskVisible: false,
      selectedProcess: {},
      historyProcessId: 0,
      taskName: "",
      tasks: [],
    };
  },

  computed: {
    formFields() {
      const fields = [];

      if (!this.addTaskForm) {
        return fields;
      }

      // console.log(this.addTaskForm.panels);
      if (this.addTaskForm.panels) {
        for (let panel of this.addTaskForm.panels) {
          for (let field of panel.fields) {
            fields.push({
              id: field.id,
              type: field.type,
              label: field.label,
            });
          }
        }

        return fields;
      }
      return fields;
    },
  },

  watch: {
    showAddTask: {
      immediate: true,
      async handler() {
        console.log("addTask");
        if (this.showAddTask) {
          console.log("addTask1");
          await this.getTaskForms();
          if (this.taskMode === "EDIT") {
            // this.formId = 3128;
            await this.addTasks(this.formEntryId);
            this.editTask(this.taskId);
          }
        }
      },
    },

    formFields: {
      // immediate: true,
      // deep: true,
      handler() {
        if (this.formFields.length) {
          const staticColumns = [
            // {
            //   id: this.$nano.id(),
            //   name: "itemid",
            //   label: "Entry #",
            //   type: "TITLE",
            //   isVisible: false,
            //   isSortable: false,
            //   isGroupable: false,
            //   dataType: DataType.SHORT_TEXT,
            // },
            {
              id: this.$nano.id(),
              name: "createdBy",
              label: "Created By",
              type: "SUBTITLE",
              isVisible: true,
              isSortable: true,
              isGroupable: true,
              dataType: DataType.SINGLE_SELECT,
            },
            {
              id: this.$nano.id(),
              name: "createdAt",
              label: "Created At",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: false,
              dataType: DataType.DATE,
            },
            {
              id: this.$nano.id(),
              name: "modifiedBy",
              label: "Modified By",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: true,
              dataType: DataType.SINGLE_SELECT,
            },
            {
              id: this.$nano.id(),
              name: "modifiedAt",
              label: "Modified At",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: false,
              dataType: DataType.DATE,
            },
          ];

          const dynamicColumns = this.formFields
            .filter((field) => this.isValidField(field.type))
            .map((field, index) => ({
              id: this.$nano.id(),
              name: field.id,
              label: field.label || field.type,
              type: index == 0 ? "TITLE" : "NORMAL",
              isVisible:
                field.label == "Reminder"
                  ? false
                  : field.label == "Notification"
                  ? false
                  : true,
              isSortable: false,
              isGroupable: false,
              apiLoad: true,
              dataType: field.type,
            }));

          //const [entryId, ...rest] = staticColumns;
          // dynamicColumns[0].type = "TITLE";

          //this.columns = [entryId, ...dynamicColumns, ...rest];

          this.columns = [...dynamicColumns, ...staticColumns];
        }
      },
    },
  },

  async created() {
    // console.log("addTask1");
    // await this.getTaskForms();
    // if (this.taskMode === "EDIT") {
    //   // this.formId = 3128;
    //   await this.addTasks(this.formEntryId);
    //   this.editTask(this.taskId);
    // }
  },

  methods: {
    findTask(taskId) {
      const tasks = [];
      this.tasks.forEach((item) => tasks.push(...item.data));

      return tasks.find((entry) => entry.id === Number(taskId));
    },

    async getTaskEntriesUsingId() {
      this.$store.commit("showLoadingBarPage");
      let filter = [];

      filter = [
        {
          filters: [
            {
              criteria: "itemId",
              condition: "IS_EQUALS_TO",
              value: this.taskId,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "",
        },
      ];

      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: "BROWSE",
        sortBy: {
          criteria: "",
          order: "DESC",
        },
        groupBy: "",
        filterBy: filter,
        itemsPerPage: 10,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      // console.log(data);

      this.tasks = data.map(({ key, value }) => ({
        key: "",
        value: key,
        data: value.map((entry) => ({
          id: entry.itemid,
          ...this.getFormFieldsData(entry),
          createdBy: entry.createdByEmail,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedByEmail,
          modifiedAt: this.$day.format(entry.modifiedAt),
          buttonAction: true,
          formId: this.formId,
        })),
      }));
    },

    async getUserWorkflow() {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.workflowList = payload;

        this.workflowList.forEach(async (field) => {
          let wflowJson = JSON.parse(field.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );
          if (
            startBlock.settings.users.indexOf(this.$store.state.session.id) > -1
          ) {
            await this.getWorkflowList(field.id);

            if (this.processInfo === null) {
              this.workflowCount = field.name;
            } else {
              this.workflowCount = `${field.name} - (${this.processInfo.length})`;
            }
            if (
              field.initiatedBy === "FORM" ||
              field.initiatedBy === "DOCUMENT_FORM"
            ) {
              let actions = [];
              let rules = wflowJson.rules.find(
                (rule) => rule.fromBlockId === startBlock.id
              );
              if (rules) {
                actions.push(rules.proceedAction);
              }
              this.taskWorkflowList.push({
                id: field.id,
                label: this.workflowCount,
                formId: field.wFormId,
                stage: startBlock.settings.label,
                actions: actions,
              });
            }
          }
        });
      }
    },

    async getTaskForms() {
      this.taskFormList = [];

      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      // console.log(data);

      if (data.length) {
        if (data[0].value.length) {
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskMode === "ADD") {
            this.addTasksList();
          }
        }
      }
    },

    addTasksList() {
      this.taskWorkflowList = [];
      if (this.taskFormList.length) {
        if (this.taskFormList.length === 1) {
          this.addTasks(this.taskFormList[0].id);
          this.formId = this.taskFormList[0].id;
          this.repositoryId = this.taskFormList[0].repositoryId;
        } else {
          this.showTaskFormListModal = true;
        }
      }
    },

    async addTasks(formId) {
      this.formId = formId;
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getForm(formId);
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.addTaskForm = JSON.parse(payload.formJson);
      if (this.taskMode === "ADD") {
        if (formId) this.isAddTaskVisible = true;
        //   this.mode = "NEW";
        this.selectedTask = {};
      }
    },

    async getWorkflowList(workflowId) {
      let inputData = {};
      inputData = {
        wId: workflowId,
        fId: this.selectedTask.formId,
        entryId: this.selectedTask.id,
      };
      const { error, payload } = await form.getWorkflowTaskList(
        workflowId,
        this.selectedTask.formId,
        this.selectedTask.id,
        inputData
      );
      this.workflowFormTaskList = payload;
      this.processInfo = payload.processInfo;

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async showHistory(processId, workflowId) {
      this.$store.commit("showLoadingBarPage");
      await this.getMyInboxList(workflowId);
      this.$store.commit("hideLoadingBarPage");
      this.selectedProcess = this.workflowRequestList.filter(
        (process) => process.processId === processId
      );
      this.historyProcessId = processId;
    },

    refresh() {
      this.$emit("refresh");
      this.$emit("update:showAddTask", false);
    },

    async editTask(taskId) {
      this.taskWorkflowList = [];
      await this.getTaskEntriesUsingId();

      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();
      this.getUserWorkflow();
      if (this.taskFormList.length === 1) {
        this.repositoryId = this.taskFormList[0].repositoryId;
      }
      this.isAddTaskVisible = true;
    },

    selectedTaskName() {
      let task = this.columns.find((item) => item.type === "TITLE")["name"];

      this.taskName = this.selectedTask[task];
    },

    isValidField(fieldType) {
      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CALCULATED",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
      ].includes(fieldType);
    },

    getFormFieldsData(entry) {
      const data = {};
      // console.log(entry);
      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
        ];
        // console.log(field.label);
        if (field.label !== "Reminder" || field.label !== "Notification") {
          // if (field.label !== "Reminder" || field.label !== "Notification") {
          data[field.id] = complexTypes.includes(field.type)
            ? JSON.parse(entry[field.id])
            : entry[field.id];
        }
      }
      // console.log(data);

      return data;
    },

    closeTaskListModal() {
      this.showTaskFormListModal = false;
      this.$emit("update:showAddTask", false);
    },

    closeAddTaskModal() {
      this.isAddTaskVisible = false;
      //this.$emit("update:showAddTask", false);
      this.$emit("close", false);
    },
  },
};
</script>
