<template>
  <Confirm
    :value="value"
    :icon="'eva-cube-outline'"
    icon-color="primary"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>Task Form List</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true }"
        >
          <SelectField
            v-model="taskFormId"
            label="
              Select Task Form Name
            "
            is-mandatory
            :options="taskFormList"
            :error="errors[0]"
            class="q-my-lg"
          />
        </ValidationProvider>

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="ok" @click="selectTaskForm" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import SelectField from "@/components/common/form/select-field/SelectField";

export default {
  name: "TaskFormListModal",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    SelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    taskFormList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      taskFormId: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },

    selectTaskForm() {
      this.$emit("selectTaskForm", this.taskFormId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
