<script>
import Pagination from "@/components/common/display/Pagination.vue";
import { workspaceNew } from "@/api/factory.js";
import TaskActions from "./TaskActions.vue";

export default {
  name: "TaskList",

  components: { Pagination, TaskActions },

  props: {
    selectedWorkspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "Task Name",
          label: "name",
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "owner",
        },
        {
          id: this.$nano.id(),
          name: "Task Assign",
          label: "assignee",
        },
        {
          id: this.$nano.id(),
          name: "Priority",
          label: "priority",
        },
        {
          id: this.$nano.id(),
          name: "Start Date",
          label: "start date",
        },
        {
          id: this.$nano.id(),
          name: "End Date",
          label: "end date",
        },
        {
          id: this.$nano.id(),
          name: "Task Status",
          label: "status",
        },
        // {
        //   id: this.$nano.id(),
        //   name: "createdBy",
        //   label: "created by",
        // },
        // {
        //   id: this.$nano.id(),
        //   name: "createdAt",
        //   label: "created at",
        // },
      ],
      tasks: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
    };
  },

  created() {
    this.getTaskList();
  },

  methods: {
    async getTaskList() {
      const { error, payload } = await workspaceNew.getTaskList(
        this.selectedWorkspace.id,
        this.currentPage,
        this.itemsPerPage
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }

      console.log("🚀 ~ getTaskList ~ payload:", payload);
      const { meta, data } = payload;
      this.totalItems = meta.totalItems || 0;
      this.tasks.push(...data[0].value);
    },

    getChipColor(key) {
      if (["High", "Reopen"].includes(key)) {
        return "red";
      }

      if (["Medium", "In Progress"].includes(key)) {
        return "orange";
      }

      if (["Low", "Closed"].includes(key)) {
        return "green";
      }

      return "secondary";
    },
  },
};
</script>

<template>
  <div id="tasks">
    <div class="table-wrapper">
      <BaseScrollbar
        :height="`calc(100vh - ${$q.fullscreen.isActive ? '190px' : '381px'})`"
        class="q-pa-md"
      >
        <table>
          <thead>
            <tr>
              <th v-for="column in columns" :key="column.id">
                {{ column.label }}
              </th>
              <th class="actions"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="task in tasks" :key="task.id">
              <td
                v-for="column in columns"
                :key="column.id"
                :class="{ title: column.name === 'Task Name' }"
              >
                <div
                  v-if="column.name === 'Priority'"
                  class="chip"
                  :class="getChipColor(task[column.name])"
                >
                  {{ task[column.name] }}
                </div>
                <div
                  v-else-if="column.name === 'Task Status'"
                  class="chip"
                  :class="getChipColor(task[column.name])"
                >
                  {{ task[column.name] }}
                </div>
                <div v-else>{{ task[column.name] }}</div>
              </td>
              <td>
                <TaskActions :task="task" />
              </td>
            </tr>
          </tbody>
        </table>
      </BaseScrollbar>
    </div>

    <div class="pagination-wrapper">
      <Pagination
        no-border
        :total-items="totalItems"
        :current-page.sync="currentPage"
        :items-per-page.sync="itemsPerPage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#tasks {
  .table-wrapper {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    margin: 16px 0px;
  }

  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;
    border: 1px solid var(--body-bg-color);

    th {
      color: var(--title-1-color);
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
      padding: 12px 8px;
      border-bottom: 1px solid var(--body-bg-color);
      background-color: var(--body-bg-color-light);

      &.actions {
        width: 48px;
      }
    }

    td {
      padding: 8px;
      border-bottom: 1px solid var(--body-bg-color);

      &.title {
        text-decoration: underline;
        cursor: pointer;
      }

      .chip {
        display: inline-block;
        padding: 4px 12px;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;

        &.green {
          color: $green;
          background-color: rgba($green, 0.1);
        }

        &.orange {
          color: $orange;
          background-color: rgba($orange, 0.1);
        }

        &.red {
          color: $red;
          background-color: rgba($red, 0.1);
        }

        &.secondary {
          color: $secondary;
          background-color: rgba($secondary, 0.1);
        }
      }
    }
  }

  .pagination-wrapper {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    padding: 16px;
  }
}
</style>
