<script>
import CardLeft from "./CardLeft.vue";
import CardRight from "./CardRight.vue";
import { mapState } from "vuex";

export default {
  components: { CardLeft, CardRight },

  props: {
    chatItem: {
      type: Object,
      default: () => {},
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    task() {
      return {
        name: this.chatItem["Task Name"],
        priority: this.chatItem["Priority"],
        startDate: this.chatItem["Start Date"],
        endDate: this.chatItem["End Date"],
        status: this.chatItem["Task Status"],
        assignee: this.chatItem["Task Assign"],
      };
    },

    isCurrentUser() {
      return this.chatItem.createdBy === this.$store.state.session.email;
    },

    isForCurrentUser() {
      return this.task.assignee === this.$store.state.session.email;
    },

    getPriorityColor() {
      if (this.task.priority === "Low") {
        return this.darkTheme ? "text-green-5" : "text-green-6";
      }

      if (this.task.priority === "Medium") {
        return this.darkTheme ? "text-orange-5" : "text-orange-6";
      }

      if (this.task.priority === "High") {
        return this.darkTheme ? "text-red-5" : "text-red-6";
      }

      return this.darkTheme ? "text-gray-6" : "text-gray-5";
    },

    getStatusColor() {
      if (this.task.status === "In Progress") {
        return this.darkTheme ? "text-green-5" : "text-green-6";
      }

      if (this.task.status === "Open") {
        return this.darkTheme ? "text-orange-5" : "text-orange-6";
      }

      if (this.task.status === "Reopen") {
        return this.darkTheme ? "text-red-5" : "text-red-6";
      }

      return this.darkTheme ? "text-gray-6" : "text-gray-5";
    },
  },
};
</script>

<template>
  <component
    :is="isCurrentUser ? 'CardRight' : 'CardLeft'"
    :created-by="chatItem.createdBy"
    :created-at="chatItem.createdAt"
    :hide-avatar="hideAvatar"
  >
    <div id="task-card">
      <div
        class="user"
        :class="[isForCurrentUser ? 'current-user' : 'normal-user']"
      >
        <span class="text-gray-5">To:</span> {{ task.assignee }}
      </div>

      <div class="content">
        <div class="header">
          <div class="icon">
            <BaseIcon name="eva-cube" color="secondary" />
          </div>
          <div class="name">{{ task.name }}</div>
        </div>

        <div class="meta">
          <div class="wrapper">
            <div class="key">Priority:</div>
            <div class="priority" :class="getPriorityColor">
              {{ task.priority }}
            </div>
          </div>

          <div class="wrapper">
            <div class="key">Start date:</div>
            <div class="value">
              {{ $day.formatDate(task.startDate) }}
            </div>
          </div>

          <div class="wrapper">
            <div class="key">Status:</div>
            <div class="status" :class="getStatusColor">
              {{ task.status }}
            </div>
          </div>

          <div class="wrapper">
            <div class="key">End date:</div>
            <div class="value">
              {{ $day.formatDate(task.endDate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
#task-card {
  .user {
    font-weight: 500;
    font-size: 12px;

    &.normal-user {
      color: var(--title-3-color);
    }

    &.current-user {
      color: var(--secondary);
    }
  }

  .content {
    .header {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 0px;
      margin: 12px 0px;
      border-top: 1px solid var(--divider-color);
      border-bottom: 1px solid var(--divider-color);
      .name {
        font-weight: 600;
      }
    }

    .meta {
      display: grid;
      grid-template-columns: 124px 1fr;
      column-gap: 16px;
      row-gap: 8px;
      font-size: 11px;

      .priority,
      .status {
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      .key {
        color: var(--icon-color-inverted);
      }

      .value {
        font-weight: 600;
        color: var(--title-3-color);
      }

      .wrapper {
        display: grid;
        gap: 8px;

        &:nth-child(odd) {
          grid-template-columns: auto 1fr;
        }

        &:nth-child(even) {
          grid-template-columns: auto 1fr;
        }
      }
    }
  }
}
</style>
