<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "CardQuickActions",

  components: { ListItem },

  data() {
    return {
      actions: ["Delete"],
    };
  },
};
</script>

<template>
  <BaseIcon id="card-quick-actions" name="mdi-dots-vertical">
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom right"
      self="top right"
      auto-close
    >
      <ListItem
        v-for="action in actions"
        :key="action"
        :label="action"
        @click="$emit(action.toLowerCase())"
      />
    </q-menu>
  </BaseIcon>
</template>

<style lang="scss" scoped>
#card-quick-actions {
  cursor: pointer;
}
</style>
