<script>
export default {
  methods: {
    selectFiles() {
      this.$refs.hiddenInput.click();
    },

    handleChange(e) {
      const files = e.target.files;
      const validFiles = [];
      const invalidFileTypes = ["exe"];

      if (!files.length) {
        return;
      }

      for (let file of files) {
        if (file.size > 50000000) {
          this.$alert.error(`${file.name} - Maximum file size exceeded`);
          return;
        }

        const fileType = file.name.split(".").reverse()[0];
        if (invalidFileTypes.includes(fileType)) {
          this.$alert.error(`${file.name} - Invalid file type`);
          return;
        }

        validFiles.push({
          name: file.name,
          type: fileType.slice(0, 4),
          size: file.size,
          status: "Uploading",
          file,
        });
      }

      this.$refs.hiddenInput.value = "";
      this.$emit("upload", validFiles);
    },
  },
};
</script>

<template>
  <div id="chat-attachment">
    <BaseActionButton
      is-flat
      icon="eva-attach"
      no-border
      @click="selectFiles"
    />
    <input ref="hiddenInput" type="file" multiple @change="handleChange" />
  </div>
</template>

<style lang="scss" scoped>
#chat-attachment {
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: -500;
  }
}
</style>
