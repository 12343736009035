<script>
import MessageCard from "./widgets/MessageCard.vue";
import DeletedItemCard from "./widgets/DeletedItemCard.vue";
import FileCard from "./widgets/FileCard.vue";
import TaskCard from "./widgets/TaskCard.vue";
import WorkflowCard from "./widgets/WorkflowCard.vue";
import LoaderCard from "./widgets/LoaderCard.vue";
import { nextTick } from "vue";

export default {
  components: { MessageCard, FileCard, TaskCard, WorkflowCard },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    chatHistory: {
      type: Array,
      default: () => [],
    },
  },

  // watch: {
  //   chatHistory: {
  //     handler() {
  //       this.scrollToBottom();
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    getComponent(name, isDeleted) {
      if (isDeleted) {
        return DeletedItemCard;
      }

      const components = {
        message: MessageCard,
        file: FileCard,
        task: TaskCard,
        workflow: WorkflowCard,
        loader: LoaderCard,
      };

      return components[name];
    },

    scrollHandle(e) {
      if (e.target.scrollTop <= 0) {
        this.$emit("loadMore");
      }
    },

    scrollToBottom() {
      const chatContainer = document.getElementById("chat-content");
      nextTick(() => {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },

    getFormattedDate(date) {
      if (this.$day.isToday(date)) {
        return "Today";
      } else if (this.$day.isYesterday(date)) {
        return "Yesterday";
      } else {
        return this.$day.formatDate(date);
      }
    },
  },
};
</script>

<template>
  <div
    id="chat-content"
    :style="{
      height: $q.fullscreen.isActive
        ? 'calc(100vh - 156px)'
        : 'calc(100vh - 348px)',
    }"
    @scroll="scrollHandle"
  >
    <template v-for="group in chatHistory">
      <div :key="group.id" class="meta date">
        {{ getFormattedDate(group.date) }}
      </div>

      <template v-for="(item, idx) in group.items">
        <component
          :is="getComponent(item.type, item.isDeleted)"
          :key="item.itemId"
          :chat-item="item"
          :hide-avatar="
            Boolean(idx && group.items[idx - 1].createdBy === item.createdBy)
          "
          @delete="() => $emit('delete', item.itemId)"
        />

        <template v-if="item.type === 'user'">
          <div :key="item.id" class="meta user">
            {{ item.createdBy }} "{{ item.action }}" {{ item.user }}
          </div>
        </template>
      </template>
    </template>

    <q-inner-loading :showing="isLoading">
      <q-spinner color="secondary" size="3em" />
    </q-inner-loading>
  </div>
</template>

<style lang="scss" scoped>
#chat-content {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .meta {
    color: var(--icon-color);
    align-self: center;

    &.date {
      margin: 16px auto;
      font-size: 12px;
    }

    &.user {
      margin: 4px auto;
      font-size: 11px;
    }
  }
}
</style>
