<script>
import ListItem from "@/components/common/ListItem.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";

export default {
  name: "FileActions",

  components: { ListItem, EmailSharing },

  props: {
    file: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          label: "Download",
          value: "download",
        },
        {
          label: "Print",
          value: "print",
        },
        {
          label: "Email",
          value: "email",
        },
      ],
      showEmailFileSheet: false,
    };
  },

  methods: {
    applyAction(action) {
      if (action === "download") {
        this.downloadFile();
      }
      if (action === "print") {
        this.printFile();
      }
      if (action === "email") {
        this.showEmailFileSheet = true;
      }
    },

    downloadFile() {
      const link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.itemId}/2`;

      window.open(link, "_blank");
    },

    printFile() {
      const link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.itemId}/2`;

      window.open(link, "_blank");
    },
  },
};
</script>

<template>
  <BaseActionButton is-flat icon="eva-more-vertical" no-border>
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom right"
      self="top right"
      auto-close
    >
      <ListItem
        v-for="action in actions"
        :key="action.value"
        :label="action.label"
        @click="applyAction(action.value)"
      />
    </q-menu>

    <EmailSharing
      v-model="showEmailFileSheet"
      :repository-id="file.repositoryId"
      :file-list="[{ id: file.itemId, name: file.name }]"
    />
  </BaseActionButton>
</template>

<style lang="scss" scoped></style>
