<template>
  <div>
    <Confirm
      v-model="showWorkflowListModal"
      :icon="'o_account_tree'"
      icon-color="primary"
      width="450px"
      @input="closeModal"
    >
      <!-- title -->

      <template #title>Workflow List</template>

      <!-- ... -->

      <template #default>
        <ValidationObserver ref="workflow">
          <!-- field -->

          <ValidationProvider
            v-slot="{ errors }"
            name="field"
            :rules="{ required: true }"
          >
            <SelectField
              v-model="workflowId"
              label="
              Select Workflow Name
            "
              is-mandatory
              :options="workflowInitiateList"
              :error="errors[0]"
              class="q-my-lg"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="closeModal"
        />

        <BaseButton label="ok" @click="selectWorkflow" />
      </template>

      <!-- ... -->
    </Confirm>

    <Sheet
      v-model="processSheet"
      :width="formWidth"
      :has-footer="!isCustomizedWorkflow || !initiateProcess"
      no-padding
      @input="closeProcessSheet(true)"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions>
        <template v-if="isCustomizedWorkflow">
          <div v-if="isRequestLocked" class="cursor-pointer q-mr-sm">
            <q-chip outline color="orange" class="cursor-pointer orange-hover">
              View Only
            </q-chip>
          </div>
          <div
            v-if="selectedProcess.ticketLockedBy === $store.state.session.id"
            class="cursor-pointer q-mr-sm"
            @click="requestReleaseModal = true"
          >
            <q-chip
              outline
              color="secondary"
              class="cursor-pointer secondary-hover"
            >
              Release
            </q-chip>
          </div>
        </template>
        <template
          v-if="!isCustomizedWorkflow && workflow.initiatedBy !== 'DOCUMENT'"
        >
          <template v-if="mandatory.filledValue || mandatory.nonFilledValue">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="mandatoryFilling"
              size="lg"
              :thickness="0.22"
              color="primary"
              track-color="grey-3"
              class="q-mr-sm"
            >
              {{ mandatoryFilling }}%
            </q-circular-progress>
          </template>
          <div v-if="mandatory.filledValue" class="chip-success q-mr-sm">
            <div class="">{{ mandatory.filledValue }} ready</div>
          </div>
          <div
            v-if="mandatory.nonFilledValue"
            class="chip-warning"
            @click="checkFormValidation"
          >
            <div class="">{{ mandatory.nonFilledValue }} need to work</div>
          </div>
        </template>
        <template v-if="mandatoryDocument.length">
          <div class="chip-warning" @click="attachmentSheet = true">
            <div class="">{{ mandatoryDocument.length }} document required</div>
          </div>
        </template>
        <template v-if="isCustomizedWorkflow">
          <BaseActionButton
            v-tooltip.top="'refresh'"
            icon="mdi-refresh"
            no-border
            is-flat
            size="20px"
            class="q-mr-sm"
            @click="refreshFormData"
          />
        </template>
        <BaseButton
          v-if="hasWflowInitiator"
          :label="subWorkflowName"
          class="q-mr-sm"
          color="secondary"
          @click="initiateSubWorkflow"
        />

        <BaseActionButton
          v-if="itemModel.itemId"
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="processDocumentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
        <template v-if="Object.keys(selectedProcess).length">
          <BaseActionButton
            v-if="!formView"
            v-tooltip.top="'Form View'"
            is-flat
            :icon="'mdi-form-select'"
            class="q q-mr-sm"
            @click="formView = true"
          />

          <BaseActionButton
            v-else
            v-tooltip.top="'Details View'"
            is-flat
            :icon="'mdi-table-column'"
            class="q-mr-sm"
            @click="formView = false"
          />
        </template>
        <BaseActionButton
          v-if="!isCustomizedWorkflow"
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <template v-if="isCustomizedWorkflow && initiateProcess">
          <CustomForm
            :workflow-id="workflow.id"
            :workflow="workflow"
            :form-id="formId"
            :panels="panels"
            :secondary-panels="secondaryPanels"
            :save-action.sync="saveAction"
            :workflow-form-model.sync="workflowFormModel"
            :actions="actions"
            :request-prefix.sync="requestPrefix"
            :ml-prediction.sync="mlPrediction"
            :form-fields="formFields"
          />
        </template>

        <template v-else>
          <template v-if="isCustomizedWorkflow && !initiateProcess">
            <div v-if="formId" class="col">
              <CustomProcessForm
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :form-fields="formFields"
                :data-validation-values.sync="dataValidationValues"
                :selected-process="selectedProcess"
                :is-readonly="isRequestLocked ? true : formEditAccess"
                :check-list.sync="checkList"
                :form-check-list.sync="formCheckList"
                :save-only.sync="saveOnly"
                :inbox="
                  (selectedNode === 'inbox' ||
                    selectedNode === 'common' ||
                    selectedNode === 'payment') &&
                  !isRequestLocked
                "
                @updateDataValidationValues="updateDataValidationValues"
              />
            </div>
          </template>
          <template v-else>
            <div v-if="formId" class="col">
              <!-- form -->
              <BaseScrollbar height="calc(100vh - 115px)">
                <div v-if="initiateProcess || formView" id="form-view">
                  <RenderForm
                    v-if="formId"
                    :form-id="formId"
                    :panels="panels"
                    :secondary-panels="secondaryPanels"
                    :form-settings="formSettings"
                    :form-model="formModel"
                    :save-action.sync="saveAction"
                    :workflow-form-model.sync="workflowFormModel"
                    :workflow-model.sync="workflowModel"
                    :is-readonly="formEditAccess"
                    :form-edit-controls="formEditControls"
                    :readonly-controls="readonlyControls"
                    :form-visibility-access="formVisibilityAccess"
                    :form-secure-controls="formSecureControls"
                    :enable-controls="_enableControls"
                    :check-list.sync="checkList"
                    :form-fields="formFields"
                    :data-validation-values="dataValidationValues"
                    :form-check-list.sync="formCheckList"
                    :form-upload-files="formUploadFiles"
                    :has-check-form-validate.sync="hasCheckFormValidate"
                    :ocr-result="ocrResult"
                    @uploadFiles="getOCRResult"
                    @mandatoryControlsList="mandatoryControlsList"
                    @deletedFiles="deletedFiles"
                  />

                  <div
                    v-if="signList.length"
                    id="approvalSignature"
                    class="q-pa-md"
                  >
                    <div class="title q-mb-sm">Approval Signature</div>

                    <BaseSeparator class="q-my-sm" />

                    <div class="row">
                      <template v-for="sign in signList">
                        <div :key="sign.id" class="col-3 q-mb-sm">
                          <img class="imgCenter col" :src="signLink(sign.id)" />
                          <div class="text row">
                            <span class="col-12 text-bold">{{
                              sign.stage
                            }}</span>
                            <span class="col-12">{{
                              sign.createdByEmail
                            }}</span>
                            <span class="col-12">{{
                              $day.format(sign.createdAt)
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>

                  <SignatureField
                    v-if="userSignature || initUserSignature"
                    v-model="signImg"
                    label="Signature"
                    class="q-pa-md"
                  />
                </div>
                <div v-else class="q-pa-md">
                  <!-- <div class="col-12 q-mb-sm q-pt-md stepper">
                  <Stepper
                    :stage-level="stageLevel"
                    :current-step="currentStep"
                  />
                </div> -->

                  <div id="requestDetails" class="col-12">
                    <div class="row justify-center">
                      <div class="col">
                        <div class="row items-center">
                          <table class="submittedData q-mb-sm">
                            <tbody>
                              <tr v-if="selectedProcess.createdByName">
                                <td colspan="4">
                                  Requested By:
                                  {{ selectedProcess.createdByName }}
                                </td>
                              </tr>
                              <tr>
                                <th v-if="selectedProcess.raisedBy">
                                  Requested User Email
                                </th>
                                <th>Requested Date</th>
                                <th>Last Acted By</th>
                                <th>Last Acted On</th>
                              </tr>
                              <tr>
                                <td v-if="selectedProcess.raisedBy">
                                  {{ selectedProcess.raisedBy }}
                                </td>
                                <td>
                                  {{ $day.format(selectedProcess.raisedAt) }}
                                </td>
                                <td>
                                  {{
                                    selectedProcess.transaction_createdByEmail
                                  }}
                                </td>
                                <td>
                                  {{
                                    $day.format(
                                      selectedProcess.transaction_createdAt
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <BaseScrollbar height="calc(100vh - 275px)">
                            <template>
                              <table class="mainData col-12">
                                <template
                                  v-for="(value, key) in selectedProcess
                                    .formData.fields"
                                >
                                  <template v-if="value">
                                    <template
                                      v-if="validateType(key) !== 'TABLE'"
                                    >
                                      <tr :key="`${key}-${value}`">
                                        <td class="tblHeader ellipsis small">
                                          {{ getLabel(key) }}
                                        </td>
                                        <td class="ellipsis">
                                          <template
                                            v-if="
                                              validateType(key) ===
                                              'FILE_UPLOAD'
                                            "
                                          >
                                            <div
                                              v-if="JSON.parse(value).length"
                                            >
                                              <div
                                                v-for="file in JSON.parse(
                                                  value
                                                )"
                                                :key="file.fileName"
                                                class="q-mb-xs"
                                              >
                                                <div class="row items-center">
                                                  <div
                                                    class="col row items-center"
                                                    @click="
                                                      viewFile(file, value)
                                                    "
                                                  >
                                                    <FileIcon
                                                      :mime-type="
                                                        fileType(file.fileName)
                                                      "
                                                      class="mini-avatar q-mr-sm"
                                                    />
                                                    <span
                                                      class="cursor-pointer text-underline text-secondary"
                                                      >{{ file.fileName }}</span
                                                    >
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </template>
                                          <template
                                            v-else-if="
                                              validateType(key) === 'DATE'
                                            "
                                          >
                                            {{ $day.format(value, false) }}
                                          </template>
                                          <template
                                            v-else-if="validateValue(value)"
                                          >
                                            {{ removeTags(value) || "-" }}
                                          </template>
                                          <template v-else>
                                            <a :href="value" target="_blank">
                                              {{ value || "-" }}
                                            </a>
                                          </template>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-else-if="JSON.parse(value).length"
                                    >
                                      <tr
                                        v-if="getLabel(key)"
                                        :key="'trlabel-' + key"
                                      >
                                        <td
                                          colspan="2"
                                          class="tblHeader ellipsis small"
                                        >
                                          <FormFieldLabel
                                            :key="'label-' + key"
                                            :label="getLabel(key)"
                                            class="q-mt-sm"
                                          />
                                        </td>
                                      </tr>
                                      <tr :key="'trtable1-' + key">
                                        <td colspan="2">
                                          <BaseScrollbar
                                            :key="'scroll-' + key"
                                            style="max-height: 200px"
                                          >
                                            <table
                                              :key="'table-' + key"
                                              class="sub-table q-mt-sm q-mb-sm"
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    v-for="(
                                                      field_item, field_key
                                                    ) in tableValue(value)[0]"
                                                    :key="field_key"
                                                  >
                                                    {{
                                                      getLabel(
                                                        field_key,
                                                        "table"
                                                      )
                                                    }}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="(
                                                    field_item, field_key
                                                  ) in tableValue(value)"
                                                  :key="field_key"
                                                >
                                                  <td
                                                    v-for="(
                                                      row_value, row_key
                                                    ) in field_item"
                                                    :key="row_key"
                                                  >
                                                    {{ row_value }}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </BaseScrollbar>
                                        </td>
                                      </tr>
                                    </template>
                                  </template>
                                </template>
                              </table>
                            </template>
                          </BaseScrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseScrollbar>
            </div>

            <div v-if="itemModel.itemId" class="col">
              <div class="fileFrame">
                <div class="file-list">
                  <iframe
                    id="file-frame"
                    ref="myProcessFiles"
                    :src="itemLink()"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </template>
          <div class="col-auto q-mr-sm leftBorder">
            <template v-for="tab in tabs">
              <template v-if="tab.value === 'TASK'">
                <BaseActionButton
                  v-if="addTaskEnabled"
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="tabButtons"
                  @click="showLinks(tab.value)"
                >
                  <span v-if="tab.count" class="badge">{{ tab.count }} </span>
                </BaseActionButton>
              </template>
              <template v-else>
                <BaseActionButton
                  v-if="
                    (tab.value !== 'VALIDATION' &&
                      tab.value !== 'PAYMENT_TABLE') ||
                    ((tab.value === 'VALIDATION' ||
                      tab.value === 'PAYMENT_TABLE') &&
                      isCustomizedWorkflow)
                  "
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="tabButtons"
                  @click="showLinks(tab.value)"
                >
                  <span v-if="tab.count" class="badge">{{ tab.count }} </span>
                </BaseActionButton>
              </template>
            </template>
          </div>
          <!-- ... -->
        </template>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template v-if="!documentSignProcess && !isRequestLocked" #footer>
        <template v-if="!isCustomizedWorkflow || !initiateProcess">
          <div v-if="actions.length" class="footer row">
            <BaseButton
              v-if="
                selectedNode === 'inbox' ||
                selectedNode === 'common' ||
                selectedNode === 'payment' ||
                initiateProcess
              "
              label="Save"
              class="q-ml-md"
              color="secondary"
              @click="validateRequest('Save')"
            />
            <template v-for="action in actions">
              <BaseButton
                v-if="
                  action === 'Approve' ||
                  action === 'Complete' ||
                  action === 'Rightsize'
                "
                :key="action"
                :label="action"
                color="positive"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Reject' ||
                  action === 'Terminate' ||
                  action === 'Force Close' ||
                  action === 'Decline'
                "
                :key="action"
                :label="action"
                color="red"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Forward' ||
                  action.indexOf('Reply') > -1 ||
                  action === 'Rollback' ||
                  action === 'Assign'
                "
                :key="action"
                :label="action"
                color="orange"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="action === 'To Requester'"
                :key="action"
                :label="action"
                color="pink"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else
                :key="action"
                :label="action"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
            </template>
          </div>
        </template>

        <template
          v-if="selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv1'"
        >
          <BaseButton
            v-if="selectedNode === 'inbox'"
            label="Verify"
            :disabled="customVerify"
            class="q-ml-md"
            color="primary"
            @click="validateRequest('CustomVerify', false, false)"
          />
        </template>
      </template>
      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess
      "
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess
        "
        #upload
      >
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox' ||
            selectedNode === 'common' ||
            selectedNode === 'payment' ||
            initiateProcess
              ? 'calc(100vh - 200px)'
              : ''
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template v-if="row.name">
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template v-if="false">
                        <template
                          v-if="
                            selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment' ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            ((selectedNode === 'inbox' ||
                              selectedNode === 'common' ||
                              selectedNode === 'payment') &&
                              file.createdBy === $store.state.session.id) ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->

                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      ((selectedNode === 'inbox' ||
                        selectedNode === 'common' ||
                        selectedNode === 'payment') &&
                        file.createdBy === $store.state.session.id) ||
                      initiateProcess
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess
          ? '30'
          : '0'
      "
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments(0)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess
        "
        class="col borderTop"
      >
        <div class="row">
          <template v-if="checkTenant()">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="q-pr-sm"
                  :class="attachComment ? 'col-auto' : 'col'"
                />

                <CheckboxField
                  v-if="
                    selectedProcess.processId &&
                    selectedProcess.stageType !== 'START' &&
                    !attachComment
                  "
                  v-model="notifyInitiator"
                  label="Notify Initiator/Requester"
                  class="col-auto notify-cmd"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>

              <CheckboxField
                v-if="
                  selectedProcess.processId &&
                  selectedProcess.stageType !== 'START' &&
                  (attachComment ||
                    !(attachmentList.length && fileNames.length))
                "
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                :class="notifyInitiatorClass"
                class="col-12 q-pl-sm q-pb-sm q-pt-sm"
              />
              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <div class="q-ml-sm text-gray q-mr-sm ellipsis" style="font-size: 10px">
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>

                    <BaseActionButton
                      v-tooltip.top="'resend'"
                      icon="mdi-send"
                      color="secondary"
                      is-flat
                      size="16px"
                      @click.stop="resendMail(row.ezMailId)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
    <Modal
      v-model="requestActionModal"
      width="70vw"
      has-footer
      @input="closeRequestActionModal"
    >
      <!-- title -->

      <template #title>Request Action</template>

      <!-- ... -->

      <template #default>
        <RenderForm
          :form-id="formId"
          :panels="panels"
          :secondary-panels="secondaryPanels"
          :form-settings="formSettings"
          :form-model="formModel"
          :workflow-form-model.sync="workflowFormModel"
          :save-action="saveAction"
        />

        <div
          v-if="
            internalForward.proceed && groupActionModalButton.name === 'Assign'
          "
        >
          <!-- user -->

          <MultiSelectField
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="userList"
            class="field q-ml-md q-mr-md q-mb-md"
          />

          <TextAreaField
            v-model="commentText"
            placeholder="Type a comment..."
            class="field q-ml-md q-mr-md q-mb-md"
          />
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          :label="groupActionModalButton.name"
          :color="groupActionModalButton.color"
          @click="submitGroupAction"
        />
      </template>

      <!-- ... -->
    </Modal>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title>
        <span v-if="!processForwardGroupAction"
          >"{{ selectedProcess.requestNo }}"</span
        >
        <template v-if="isTenantGOTO() || isTenantArmgroup()">
          Process Assign
        </template>
        <template v-else> Process Forward </template>
      </template>

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <template v-if="isTenantArmgroup()">
            <!-- user -->

            <SelectField
              v-model="armgroupForwardUser"
              label="users"
              :options="userList"
              class="field q-mb-md"
            />

            <!-- ... -->
          </template>
          <template v-else>
            <!-- user -->

            <MultiSelectField
              v-model="forwardUsers"
              label="users"
              :options="userList"
              class="field q-mb-md"
            />

            <!-- ... -->

            <!-- group -->

            <MultiSelectField
              v-model="forwardGroups"
              label="group"
              :options="groupList"
              class="field"
            />

            <!-- ... -->

            <TextAreaField
              v-if="processForwardGroupAction"
              v-model="commentText"
              placeholder="Type a comment..."
              class="field q-mt-md"
            />
          </template>
        </div>
      </template>

      <template #footer>
        <template v-if="isTenantGOTO() || isTenantArmgroup()">
          <BaseButton
            label="Assign"
            color="orange"
            @click="
              {
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Forward"
            color="orange"
            @click="
              {
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
      </template>
    </Modal>

    <Sheet
      v-model="jiraIssueSheet"
      width="40vw"
      has-footer
      @input="closeJiraIssueSheet"
    >
      <!-- title -->

      <template #title> JIRA - Create Issue </template>

      <!-- ... -->

      <template #default>
        <div id="content-wrapper" class="jiraIssue">
          <ValidationObserver ref="form">
            <div class="field-wrapper">
              <ValidationProvider
                v-slot="{ errors }"
                name="Issue Name"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueName"
                  label="Issue Name"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Project Key"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="projectKey"
                  label="Project Key"
                  is-mandatory
                  :error="errors[0]"
                  tooltip="Unique identifier key of your Project"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Issue Type"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueType"
                  label="Issue Type"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                :rules="{ required: true }"
              >
                <TextAreaField
                  v-model="issueDescription"
                  label="Description"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <!-- <ValidationProvider
              v-slot="{ errors }"
              name="Epic Value"
              :rules="{ required: true }"
            >
              <TextField
                v-model="issueEpicValue"
                label="Epic Value"
                is-mandatory
                :error="errors[0]"
                class="field"
              />
            </ValidationProvider> -->

              <CheckboxField
                v-if="false"
                v-model="issueDescriptionTable"
                label="Include inbox request information as a table of issue description"
                class="field"
              />
              <!-- v-if="issueDescriptionTable" -->
              <MultiSelectField
                v-if="false"
                v-model="issueDescriptionFields"
                label="Request Fields"
                :is-searchable="true"
                :options="formFields"
                class="field"
              />

              <SelectField
                v-if="false"
                v-model="issueAssignee"
                label="Assignee"
                :is-searchable="true"
                :options="issueAssigneeUsers"
                class="field"
              />
            </div>
          </ValidationObserver>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Create Issue"
          color="primary"
          @click="createJiraIssue"
        />
      </template>
    </Sheet>

    <Modal
      v-model="jiraConfirmModal"
      width="40vw"
      height="70vh"
      has-footer
      @input="closeJiraConfirmModal"
    >
      <!-- title -->

      <template #title> JIRA - Issue Created Successful </template>

      <!-- ... -->

      <template #default>
        <div>
          <div class="field-wrapper">
            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="REF# ID" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference Id'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.id)"
                ></BaseActionButton>
              </div>
            </div>

            <TextField v-model="issueRef.id" is-readonly class="q-mb-sm" />

            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="KEY" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference Key'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.key)"
                ></BaseActionButton>
              </div>
            </div>
            <TextField v-model="issueRef.key" is-readonly class="q-mb-sm" />

            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="REF# URL" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference URL'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.self)"
                ></BaseActionButton>
              </div>
            </div>
            <TextField v-model="issueRef.self" is-readonly class="q-mb-sm" />
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="closeJiraConfirmModal"
        />
      </template>
    </Modal>

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="addTask()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="viewTask(task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <AddTaskNew
      v-if="showAddTask"
      v-model="showAddTask"
      :show-add-task="showAddTask"
      :task-mode="taskMode"
      task-id=""
      form-entry-id=""
    />

    <Modal
      v-model="dataValidationModal"
      width="70vw"
      @input="dataValidationModal = false"
    >
      <!-- title -->

      <template #title> Data Verification [Claimant's Statement]</template>

      <!-- ... -->

      <template #default>
        <div id="dataValidation">
          <table>
            <thead>
              <tr>
                <!-- columns -->

                <th v-for="column in dataValidationColumns" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in dataValidationValues" :key="item.id">
                <!-- columns -->

                <td v-for="column in dataValidationColumns" :key="column.id">
                  <slot
                    :name="column.name"
                    :value="item[column.name]"
                    :item="item"
                  >
                    <template v-if="column.type === 'STATUS'">
                      <template v-if="item[column.name] === 'Matched'">
                        <span class="text-green"> {{ item[column.name] }}</span>
                      </template>
                      <template v-else-if="item[column.name] === 'Verified'">
                        <span class="text-primary">
                          {{ item[column.name] }}</span
                        >
                      </template>
                      <template v-else-if="item[column.name] === 'Not Matched'">
                        <span class="text-red"> {{ item[column.name] }}</span>
                      </template>
                      <template v-else>
                        <span class="text-orange">
                          {{ item[column.name] || "-" }}</span
                        >
                      </template>
                    </template>
                    <template v-else>
                      {{ item[column.name] || "-" }}
                    </template>
                  </slot>
                </td>

                <!-- ... -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>

    <Confirm
      v-model="requestReleaseModal"
      icon="eva-alert-circle-outline"
      icon-color="orange"
      @input="requestReleaseModal = false"
    >
      <template #title> Request release confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Please confirm the request "<b>{{ selectedProcess.requestNo }}</b
            >, has been released by you to be approved by others.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          color="orange"
          @click="requestReleaseModal = false"
        />
        <BaseButton
          label="Confirm"
          color="orange"
          @click="
            {
              requestReleaseModal = false;
              releaseReservedRequest(selectedProcess);
              closeProcessSheet(true);
            }
          "
        />
      </template>
    </Confirm>

    <Modal
      v-model="paymentTableModal"
      width="70vw"
      :has-footer="selectedNode === 'inbox' || selectedNode === 'payment'"
      @input="paymentTableModal = false"
    >
      <!-- title -->

      <template #title> Payment Table</template>

      <!-- ... -->

      <template #action>
        <BaseActionButton
          v-tooltip.top="'Export'"
          icon="mdi-table-arrow-right"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="export_Data"
        />
      </template>

      <template #default>
        <div id="paymentTable">
          <template
            v-if="
              selectedNode === 'inbox' &&
              (selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv' ||
                !isPaymentFound)
            "
          >
            <ValidationObserver ref="form">
              <div class="row">
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Total Payment"
                    :rules="{ required: true, min_value: 1 }"
                    class="col"
                  >
                    <NumberField
                      v-model="totalPayment"
                      label="Total Payment"
                      is-mandatory
                      class="q-pa-sm"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Lumpsum Amount"
                    :rules="{ required: true, min_value: 1, max_value: 2000 }"
                    class="col"
                  >
                    <NumberField
                      v-model="lumpsumAmount"
                      label="Lumpsum Amount"
                      is-mandatory
                      class="q-pa-sm"
                      :max="2000"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Benefit Type"
                    :rules="{ required: true }"
                    class="col"
                  >
                    <SelectField
                      v-model="benefitType"
                      label="Benefit Type"
                      is-mandatory
                      :options="benefitTypes"
                      class="q-pa-sm"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <BaseButton
                    label="Calculate"
                    class="calculateBtn col-auto q-ml-md"
                    @click="paymentCalculation"
                  />
                </div>
              </div>
            </ValidationObserver>
            <div
              class="row col"
              style="display: flex; justify-content: flex-end"
            >
              <FormFieldLabel
                label="Number of payments to approve"
                class="q-mr-sm q-mt-xs"
              />
              <NumberField
                v-model="numberOfApprovePayment"
                class="q-pa-sm"
                :min="0"
                :max="paymentTableValues.length"
                :is-clearable="false"
                :is-readonly="!paymentTableValues.length"
              />
              <BaseButton
                label="Payment Approve"
                color="secondary"
                class="q-ml-sm"
                style="margin-top: 12px"
                :disabled="!paymentTableValues.length"
                @click="paymentApprove"
              />
              <BaseButton
                label="Clear"
                color="red"
                class="q-ml-sm"
                style="margin-top: 12px"
                :disabled="!paymentTableValues.length"
                @click="clearPaymentApprove"
              />
            </div>
          </template>
          <div class="col q-pa-sm">
            <BaseScrollbar
              :height="
                selectedNode === 'inbox' &&
                (selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv' ||
                  !isPaymentFound)
                  ? 'calc(100vh - 510px)'
                  : ''
              "
            >
              <table class="q-mt-sm">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in paymentTableColumns" :key="column.id">
                      {{ column.label }}
                    </th>

                    <!-- ... -->

                    <th
                      v-if="
                        selectedProcess.activityId ===
                          '90xxPehjko0dNYRjbX5wm' && isPaymentFound
                      "
                      class="actions"
                    >
                      <BaseActionButton
                        v-tooltip:top="'add row'"
                        color="primary"
                        icon="eva-plus"
                        no-border
                        @click="addPaymentRow"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in paymentTableValues"
                    :key="'paymentrow' + index"
                  >
                    <!-- columns -->

                    <td v-for="column in paymentTableColumns" :key="column.id">
                      <template
                        v-if="
                          selectedProcess.activityId ===
                            '90xxPehjko0dNYRjbX5wm' &&
                          isPaymentFound &&
                          !item['delete']
                        "
                      >
                        <template v-if="column.name === 'approval'">
                          <template v-if="item[column.name] === 'Approved'">
                            <span
                              :name="column.name"
                              :value="item[column.name]"
                              :item="item"
                              class="text-green"
                            >
                              {{ item[column.name] }}
                            </span>
                          </template>
                          <template v-else>
                            <span
                              :name="column.name"
                              :value="item[column.name]"
                              :item="item"
                              class="text-red"
                            >
                              {{ item[column.name] }}
                            </span>
                          </template>
                        </template>
                        <template v-else-if="column.name === 'paymentDate'">
                          <DateField v-model="item[column.name]" />
                        </template>
                        <template v-else>
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="column.type === 'SINGLE_SELECT'">
                          <SelectField
                            v-model="item[column.name]"
                            :options="approvalTypes"
                            @input="updateApprovalDate(index)"
                          />
                        </template>
                        <template v-else-if="column.type === 'DATE'">
                          <DateField v-model="item[column.name]" />
                        </template>
                        <template
                          v-else-if="
                            column.name === 'baseAmount' && item['delete']
                          "
                        >
                          <NumberField
                            v-model="item[column.name]"
                            placeholder="0.00"
                            @input="calcOtherColumns(index)"
                          />
                        </template>
                        <template v-else>
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </template>
                      </template>
                    </td>

                    <!-- ... -->

                    <td
                      v-if="
                        selectedProcess.activityId ===
                          '90xxPehjko0dNYRjbX5wm' && isPaymentFound
                      "
                      class="actions"
                    >
                      <BaseActionButton
                        v-if="item['delete']"
                        v-tooltip="'delete row'"
                        is-flat
                        color="red"
                        icon="eva-trash-outline"
                        no-border
                        @click="removePaymentRow(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </div>
      </template>

      <template
        v-if="selectedNode === 'inbox' || selectedNode === 'payment'"
        #footer
      >
        <template v-if="selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv'">
          <BaseButton
            label="Recommend to Approve"
            color="green"
            class="q-mr-sm"
            @click="
              {
                paymentTableModal = false;
                customCloseProcessSheet = !customCloseProcessSheet;
                updatePaymentTable('Recommend to Approve');
              }
            "
          />
          <BaseButton
            label="Recommend to Reject"
            color="red"
            @click="
              {
                paymentTableModal = false;
                customCloseProcessSheet = !customCloseProcessSheet;
                updatePaymentTable('Recommend to Reject');
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Update Payments"
            color="primary"
            @click="
              {
                paymentTableModal = false;
                updatePaymentTable();
              }
            "
          />
        </template>
      </template>
    </Modal>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>
    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="successModal"
      icon="eva-checkmark-circle-2-outline"
      icon-color="green"
      @input="successModal = false"
    >
      <template #title>Success</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Your request for Jira ticket creation has been submitted and the
            process has been initiated. You will receive notifications once the
            process is complete.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="successModal = false"
        />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          advancedCommentsSheet = false;
        }
      "
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <div
            v-if="attachmentList.length && fileNames.length"
            class="row actions advance-cmd"
          >
            <CheckboxField
              v-model="attachComment"
              label="Add Comment to PDF"
              class="col-auto q-pr-sm"
              style="margin: auto"
            />

            <SelectField
              v-if="attachComment"
              v-model="selectedFileForAttach"
              :is-searchable="true"
              :options="fileNames"
              class="field comment-attach"
            />
            <CheckboxField
              v-if="
                selectedProcess.processId &&
                selectedProcess.stageType !== 'START'
              "
              v-model="notifyInitiator"
              label="Notify Initiator/Requester"
              class="col-auto q-pl-sm adv-notify-cmd"
            />
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <!-- <p class="text-secondary">
        After submitting, you can upload the required documents from sent
        list once you have them.
      </p> -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="proceedSubmission" />
      </template>
    </Confirm>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = '';
          actionPasswordConfirm = false;
        }
      "
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          actionConfirmAlert = false;
        }
      "
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "<b>{{
              saveAction
            }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              processRequest(false, false);
            }
          "
        />
      </template>
    </Confirm>

    <MetaDataEdit
      v-if="showMetaDataEditModal"
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      :title="metaDataTitle || 'Index'"
      :columns="columns"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      :meta-data-fields="metaDataModal"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <Sheet
      v-model="showCustomProcessForm"
      :width="formWidth"
      :has-footer="!isCustomizedWorkflow"
      no-padding
      @input="showCustomProcessForm = false"
    >
      <!-- title -->

      <template #title> Custom Form </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div class="col">
          <template v-if="isCustomizedWorkflow">
            <CustomProcessForm
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
              :actions="actions"
            />
          </template>
        </div>

        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              v-if="tab.value !== 'PAYMENT_TABLE' && isCustomizedWorkflow"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer> </template>
      <!-- ... -->
    </Sheet>

    <SubWorkflowProcess
      :workflow-id="subWorkflowId || subProcessData.id"
      :initiate-sub-workflow-process="initiateSubWorkflowProcess"
      :main-workflow-id="workflow.id"
      :main-process-id="selectedProcess.processId"
      :sub-process-link="subProcessLink"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :main-form-fields="formFields"
      :main-form-model="formModel"
      :sub-process-id.sync="subProcessId"
      @closeProcessSheet="closeSubProcess"
    />

    <Sheet v-model="subProcessSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Linked Request </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="(process, index) in subProcessTickets">
          <div :key="index" class="subProcess">
            <div class="title row">
              <div class="col-auto">
                <BaseIcon
                  name="o_account_tree"
                  class="q-mr-sm"
                  color="primary-4"
                />
              </div>
              <div
                class="col ellipsis text-underline"
                @click="openSubProcess(process)"
              >
                {{ process.requestNo }}
              </div>
              <q-chip
                v-if="process.flowStatus === '1'"
                size="10px"
                color="green"
                text-color="white"
              >
                Completed
              </q-chip>
              <q-chip v-else size="10px" color="orange" text-color="white">
                Running
              </q-chip>
            </div>

            <div class="meta row">
              <div class="col row">
                <div>{{ process.name }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.stage }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.duration }} ago</div>
              </div>
            </div>
            <div v-if="false" class="col-auto q-mr-sm q-pt-md leftBorder">
              <template v-for="tab in subTabs">
                <BaseActionButton
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="linkActions"
                  size="16px"
                  @click="subProcessShowLinks(tab.value, process)"
                >
                  <span
                    v-if="
                      tab.value === 'ATTACHMENTS' && process.attachmentCount
                    "
                    class="badge"
                    >{{ process.attachmentCount }}
                  </span>

                  <span
                    v-if="tab.value === 'COMMENTS' && process.commentsCount"
                    class="badge"
                    >{{ process.commentsCount }}
                  </span>
                </BaseActionButton>
              </template>
            </div>
          </div>
        </template>
      </div>
    </Sheet>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import SelectField from "@/components/common/form/select-field/SelectField";
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import {
  workflow,
  repository,
  uploadAndIndex,
  form,
  integration,
  auth,
  transaction,
  custom,
  //   menu,
  report,
} from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
// import { Portal } from "portal-vue";
// import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
// import DataType from "@/constants/data-type.js";
// import ProcessList from "./ProcessList.vue";
import ListItem from "@/components/common/ListItem.vue";
import Modal from "@/components/common/popup/Modal.vue";
// import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
// import SortItems from "@/components/common/display/item-actions/components/SortItems.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
// import Pagination from "@/components/common/display/Pagination.vue";
// import ImportPopup from "@/components/common/display/item-actions/components/ImportPopup.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import axios from "axios";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
// import FileDetails from "./FileDetails";
import FileDetails from "@/views/workflows/views/workflows-inbox/components/FileDetails.vue";
// import { copyToClipboard } from "quasar";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import CustomForm from "@/views/custom/CustomForm.vue";
import CustomProcessForm from "@/views/custom/CustomProcessForm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import Tabs from "@/components/common/tabs/Tabs.vue";
import { exportData } from "@/helpers/export-excel-data";
// import SearchItems from "@/components/common/SearchItems.vue";
// import { mapState } from "vuex";
// import SubWorkflowProcess from "./components/SubWorkflow";
import SubWorkflowProcess from "@/views/workflows/views/workflows-inbox/components/components/SubWorkflow.vue";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import { decrypt } from "@/helpers/crypto";
import { isEmpty, lowerCase } from "lodash-es";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import AddTaskNew from "./AddTaskNew.vue";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";

export default {
  name: "AddNewRequest",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    SelectField,
    AddTaskNew,
    Sheet,
    RenderForm,
    FormFieldLabel,
    CheckboxField,
    TextAreaField,
    TextField,
    // Portal,
    // ItemActions,
    // ProcessList,
    ListItem,
    Modal,
    // FilterItems,
    // SortItems,
    MultiSelectField,
    // Pagination,
    // ImportPopup,
    FileIcon,
    SignatureField,
    EmailSharing,
    FileDetails,
    SingleChoiceField,
    CustomForm,
    CustomProcessForm,
    TextBuilderField,
    PasswordField,
    MetaDataEdit,
    Tabs,
    // SearchItems,
    SubWorkflowProcess,
    ProcessHistory,
    AddTask,
  },

  props: {
    addRequest: {
      type: Boolean,
      default: false,
    },

    inboxMode: {
      type: String,
      default: "",
    },

    workflowid: {
      type: Number,
      default: 0,
    },

    processId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      workflowList: [],
      showWorkflowListModal: false,
      workflowId: 0,
      workflowInitiateList: [],
      workflow: {},
      selectedNode: "",
      myInbox: false,
      workflowRequestList: [],
      groupKey: [],
      wform: {},
      repository: {},
      selectedProcess: {},
      processActions: [],
      processActionsAddOn: [],
      fullView: false,
      activeView: "",
      internalForward: {},
      userList: [],
      groupList: [],
      masterFormId: 0,
      dataImported: false,
      userSignature: false,
      taskFormId: 0,
      taskEntryId: [],
      taskEntries: [],
      checkList: [],
      customReply: false,
      userReply: false,
      toRequester: false,
      // dataValidationValues: [],
      customCloseProcessSheet: false,
      selectedCompletedNode: "",
      hideColumns: [],
      addTaskEnabled: false,
      hideActions: ["sort", "group"],
      search: "",
      initiate: false,
      importExcel: false,
      processSheet: false,
      headerTitle: "",
      formModel: {},
      form: {},
      formId: "",
      actions: [],
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      documentRequired: false,
      hasSelectedProcess: 0,
      hasSelectedWorkflow: 0,
      initiateProcess: false,
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Sub Process",
          value: "SUB_PROCESS",
          icon: "eva-link-2-outline",
          count: 0,
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Payment Table",
        //   value: "PAYMENT_TABLE",
        //   icon: "mdi-table",
        //   count: 0,
        // },
      ],
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      attachmentSheet: false,
      commentsSheet: false,
      mailTransactionSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      fileSheet: false,
      selectedFile: {},
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      columns: [],
      requestActionModal: false,
      groupActionModalButton: {},
      groupActionKey: "",
      groupActionFields: [],
      workflowRequestListData: [],
      armgroupForwardUser: "",
      processForwardModal: false,
      forwardUsers: [],
      forwardGroups: [],
      processForwardGroupAction: false,
      selectedFilter: [],
      filterBy: [],
      attachmentLoad: null,
      signImg: "",
      signList: [],
      selectAll: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileDetailsSheet: false,
      fileData: [],
      jiraConnect: false,
      jiraAccounts: [],
      jiraIssueSheet: false,
      issueName: "",
      projectKey: "",
      issueType: "",
      issueDescription: "",
      issueEpicValue: "",
      issueAssignee: "",
      issueAssigneeUsers: [],
      issueDescriptionTable: false,
      issueDescriptionFields: [],
      jiraConfirmModal: false,
      issueRef: {},
      taskSheet: false,
      itemModel: {},
      errorMessage: "",
      errorModal: false,
      successModal: false,
      formWidth: "100vw",
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      formFields: [],
      checkListRowIndex: null,
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      requestPrefix: "",
      mlPrediction: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      docWarning: false,
      alertModal: false,
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      actionComments: false,
      actionConfirm: false,
      actionConfirmAlert: false,
      repositoryDetails: [],
      repositoryField: [],
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      workflowModel: {},
      customVerify: false,
      formCheckList: false,
      reuploadFile: {},
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      scrollPosition: 0,
      commentsLoad: null,
      mailTransactionLoad: null,
      documentLoad: null,
      documentLoadList: [],
      uploadFilename: "",
      filterSearchColumn: {},
      selectedFilterColumn: "",
      showCustomProcessForm: false,
      watchViewerClose: null,
      saveOnly: false,
      hasWflowInitiator: false,
      subWorkflowId: 0,
      initiateSubWorkflowProcess: false,
      subProcessSheet: false,
      subProcessData: {},
      subTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      subProcessLink: "",
      openSubProcessSheet: false,
      subProcessTickets: [],
      documentSignProcess: false,
      formUploadFiles: [],
      attachComment: false,
      fileNames: [],
      selectedFileForAttach: 0,
      displayInternalPublic: false,
      mandatory: {},
      fileControlDeleteFile: [],
      hasCheckFormValidate: false,
      subWorkflowName: "",
      fileUploadForControl: false,
      fileUploadInput: {},
      metaDataModal: {},
      metaDataTitle: "",
      requestReleaseModal: false,
      notifyInitiator: false,
      initUserSignature: false,
      isRequestLocked: false,
      selectedSubWorkflow: {},
      ocrResult: [],
      formView: true,
      stageLevel: [],
      currentStep: 0,
      subProcessId: 0,
      historyProcessId: 0,
      showAddTask: false,
      taskMode: "",
      dataValidationValues: [],
      dataValidationModal: false,
      dataValidationColumns: [
        {
          id: this.$nano.id(),
          name: "fieldName",
          label: "FIELD",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "formValue",
          label: "FORM DATA",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "ocrValue",
          label: "PDF DATA",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "STATUS",
          type: "STATUS",
        },
      ],

      paymentTableModal: false,
      totalPayment: 0,
      lumpsumAmount: 0,
      benefitType: "",
      benefitTypes: [
        {
          id: this.$nano.id(),
          label: "Monthly",
          value: "Monthly",
        },
        {
          id: this.$nano.id(),
          label: "Bi-weekly",
          value: "BiWeekly",
        },
        {
          id: this.$nano.id(),
          label: "Semi-monthly",
          value: "SemiMonthly",
        },
        {
          id: this.$nano.id(),
          label: "Weekly",
          value: "Weekly",
        },
      ],
      numberOfApprovePayment: 0,
      paymentTableColumns: [
        {
          id: this.$nano.id(),
          name: "paymentNumber",
          label: "Payment Number",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "baseAmount",
          label: "Payment Base Amount",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "claimAmount",
          label: "Claim Amount (20% Increased)",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "cumulativeTotal",
          label: "Cumulative Total",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "approval",
          label: "Approval",
          type: "SINGLE_SELECT",
        },
        {
          id: this.$nano.id(),
          name: "approvedDate",
          label: "Approved Date",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "paymentDate",
          label: "Payment Date",
          type: "DATE",
        },
      ],
      paymentTableValues: [],
      isPaymentFound: false,
      taskFormList: [],
      // taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      // taskEntryId: [],
      // taskEntries: [],
      taskEntryList: [],
      // taskMode: "",
      selectedTask: {},
      selectedWorkflow: {},
    };
  },

  computed: {
    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        let panels = [];
        this.form.panels.map((panel) => {
          let panelFields = [];
          panel.fields.forEach((field) => {
            if (this.groupActionFields.indexOf(field.id) > -1) {
              panelFields.push(field);
            }
          });
          panels.push({
            id: panel.id,
            fields: panelFields,
            settings: panel.settings,
          });
        });
        return panels;
      }
      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        return [];
      }
      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    isMaximized() {
      return this.formWidth === "100vw";
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (this.panels) {
        this.panels.forEach((panel) => {
          if (panel.fields.length) {
            let panelFields = panel.fields.filter(
              (field) =>
                field.type === "SINGLE_SELECT" ||
                field.type === "SINGLE_CHOICE" ||
                field.type === "MULTIPLE_CHOICE" ||
                field.type === "MULTI_SELECT"
            );
            if (panelFields.length) {
              panelFields.forEach((row) => {
                if (row.settings.validation.enableSettings) {
                  if (row.settings.validation.enableSettings.length) {
                    let controls = [];
                    row.settings.validation.enableSettings.forEach((tblrow) => {
                      if (tblrow.controls.length) {
                        controls.push({
                          value: tblrow.value,
                          childControls: tblrow.controls,
                        });
                        tblrow.controls.forEach((control) => {
                          if (controls.indexOf(control) < 0) {
                            listControls.push(control);
                          }
                        });
                      }
                    });
                    if (controls.length) {
                      enableControls.push({
                        id: row.id,
                        parentControls: controls,
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }

      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    isCustomizedWorkflow() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflow.id === 1014) {
          return true;
        }
      } else if (
        this.isTenantArmgroup() ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.id === 1) {
          return true;
        }
      }
      return false;
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },

    commentsSheetHeight() {
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "payment" ||
        this.selectedNode === "common" ||
        this.initiateProcess
      ) {
        if (this.checkCommentsOption() && !this.isCustomizedWorkflow) {
          if (this.attachmentList.length && this.fileNames.length) {
            if (this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 270px)";
            } else if (this.attachComment && !this.displayInternalPublic) {
              return "calc(100vh - 230px)";
            } else if (!this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 225px)";
            } else {
              return "calc(100vh - 200px)";
            }
          } else if (this.displayInternalPublic) {
            return "calc(100vh - 220px)";
          } else if (this.selectedProcess.stageType !== "START") {
            return "calc(100vh - 180px)";
          } else {
            return "calc(100vh - 150px)";
          }
        } else {
          return "calc(100vh - 150px)";
        }
      }
      return "";
    },

    mandatoryDocument() {
      return this.checkList.filter((row) => row.required && !row.attach);
    },

    mandatoryFilling() {
      if (this.mandatory.filledValue && this.mandatory.nonFilledValue) {
        return Math.floor(
          (this.mandatory.filledValue /
            (this.mandatory.filledValue + this.mandatory.nonFilledValue)) *
            100
        );
      } else if (this.mandatory.filledValue) {
        return 100;
      }
      return 0;
    },
  },

  watch: {
    addRequest: {
      immediate: true,
      handler() {
        console.log(this.addRequest);
        if (this.addRequest) {
          this.getUserWorkflow();
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          if (this.initiateProcess) {
            this.checkRequiredDocument();
            //this.initiateRequest();
          } else if (this.groupActionFields.length) {
            this.groupProcessRequest();
          } else {
            this.checkRequiredDocument();
          }
        }
      },
    },

    subWorkflowId() {
      if (this.subWorkflowId) {
        let subWorkflow = this.workflowList.find(
          (workflow) => workflow.id == this.subWorkflowId
        );
        if (subWorkflow) {
          this.subWorkflowName = `Initiate - ${subWorkflow.name}`;
          this.hasWflowInitiator = false;
          let wflowJson = JSON.parse(subWorkflow.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );

          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              subWorkflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.hasWflowInitiator = true;
              }

              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();

                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.hasWflowInitiator = true;
                }
              }
            }
          }
        } else {
          this.hasWflowInitiator = false;
        }
      }
    },
  },

  created() {
    // this.getUserWorkflow();
  },

  methods: {
    async getWorkflowTicketList() {
      this.$store.commit("showLoadingBar");
      this.workflowRequestList = [];

      // this.list = [];
      const { error, payload } = await report.getWorkflowData(this.workflowid, {
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: Number(this.processId),
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 100,
        currentPage: 1,
        mode: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          this.workflowRequestList.push({
            ...process,
          });
        });
      });

      console.log(this.workflowRequestList);

      this.selectedProcess = this.workflowRequestList.find(
        (item) => item.processId === Number(this.processId)
      );
      if (
        this.selectedProcess.activityUserEmail ===
        this.$store.state.session.email
      ) {
        this.selectedNode = "inbox";
        this.showActionButton(this.processId, this.workflowid);
      } else {
        this.selectedNode = "sent";
      }

      // await this.getWorkflow(id, pId);
    },

    async getUserWorkflow() {
      this.workflowList = [];
      this.workflowInitiateList = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      if (payload.length) {
        this.workflowList = payload;
        payload.forEach((workflow) => {
          let wflowJson = JSON.parse(workflow.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );

          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              workflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.workflowInitiateList.push({
                  id: this.$nano.id(),
                  label: workflow.name,
                  value: workflow.id,
                });
              }
              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();
                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.workflowInitiateList.push({
                    id: this.$nano.id(),
                    label: workflow.name,
                    value: workflow.id,
                  });
                }
              }
            }
          }
        });
        if (this.inboxMode === "ADD") {
          this.showWorkflowListModal = true;
        } else {
          let workflow = this.workflowList.find(
            (list) => list.id === this.workflowid
          );
          if (workflow) {
            this.workflow = workflow;
            this.selectedWorkflow = workflow;

            await this.getWorkflowTicketList();

            this.openProcess();
          }
        }
      }

      //   console.log(this.workflowList);
    },

    async getForm(formId) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getForm(formId);
      //this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.wform = payload;
        this.form = JSON.parse(this.wform.formJson);
      }
    },

    closeModal() {
      this.showWorkflowListModal = false;
      this.$emit("update:addRequest", false);
    },

    async selectWorkflow() {
      const isValid = await this.$refs.workflow.validate();

      if (!isValid) {
        return;
      }

      let workflow = this.workflowList.find(
        (list) => list.id === this.workflowId
      );
      if (workflow) {
        this.workflow = workflow;
        this.selectedWorkflow = workflow;

        this.openProcess(true);
      }
      this.showWorkflowListModal = false;
    },

    async openProcess(initiate = false) {
      this.actions = [];
      this.form = {};
      this.formId = "";
      this.isRequestLocked = false;
      this.displayInternalPublic = false;
      this.getRepository();
      this.ocrResult = [];
      this.hasWflowInitiator = false;
      if (initiate) {
        this.tabs.find((tab) => {
          tab.count = 0;
        });
        // this.$emit("update:selectedProcess", {});
        // this.$emit("update:taskEntries", []);
        this.selectedProcess = {};
        this.taskEntries = [];
        this.commentsList = [];
        this.attachmentList = [];
        this.initUserSignature = false;
        this.initiateProcess = true;
        this.formModel = {};
        this.signList = [];
        this.formEditAccess = false;
        this.formEditControls = [];
        this.readonlyControls = [];
        this.formVisibilityAccess = true;
        this.formSecureControls = [];
        let wflowJson = JSON.parse(this.workflow.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (startBlock.settings.initiateMode === "MANUAL") {
            this.headerTitle = `${startBlock.settings.label}`;
            let rules = wflowJson.rules.find(
              (rule) => rule.fromBlockId === startBlock.id
            );
            if (rules) {
              this.actions.push(rules.proceedAction);
            }
          }
          if (startBlock.settings.formEditAccess) {
            if (startBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = startBlock.settings.formEditControls;
            }
          }
          if (startBlock.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = startBlock.settings.formSecureControls;
          }
          this.initUserSignature = startBlock.settings.userSignature;
          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              let documentCheckList =
                startBlock.settings.fileSettings.fileCheckList;
              documentCheckList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.docWarning = startBlock.settings.fileSettings.partial;
              // if (documentCheckList.length) {
              //   documentCheckList.forEach((row) => {
              //     if (row.required && !row.attach) {
              //       this.docWarning = true;
              //     }
              //   });
              // }
              //   this.$emit("update:checkList", documentCheckList);
              this.checkList = documentCheckList;
            }
          } else {
            // this.$emit("update:checkList", []);
            this.checkList = [];
          }

          if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
            this.displayInternalPublic = true;
          }
          if (startBlock.settings.workflowInitiator) {
            this.subWorkflowId = startBlock.settings.subWorkflowId;
          }
        }
      } else {
        // this.showHistoryStepper(
        //   this.selectedProcess.processId,
        //   this.selectedProcess.id
        // );
        if (
          this.selectedNode === "inbox" ||
          this.selectedNode === "common" ||
          this.selectedNode === "payment"
        ) {
          this.formEditAccess = false;
          let wflowJson = JSON.parse(this.workflow.flowJson);

          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = actionBlock.settings.formEditControls;
            }
            if (actionBlock.settings.formVisibilityAccess) {
              this.formVisibilityAccess = true;
              this.formSecureControls = [];
            } else {
              this.formVisibilityAccess = false;
              actionBlock.settings.formSecureControls.forEach((control) => {
                try {
                  let object = JSON.parse(control);
                  if (object.userId === this.$store.state.session.id) {
                    this.formSecureControls.push(...object.formFields);
                  }
                } catch (e) {
                  this.formSecureControls.push(control);
                }
              });
            }

            if (
              wflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
            ) {
              let requestNumberFormat = JSON.parse(
                wflowJson.settings.general.processNumberPrefix
              );
              this.readonlyControls = [];
              let requestColumn = requestNumberFormat.filter(
                (row) => row.key === "formColumn"
              );
              if (requestColumn.length) {
                requestColumn.forEach((row) => {
                  this.readonlyControls.push(row.value);
                  if (this.formEditControls.indexOf(row.value) > -1) {
                    this.formEditControls.splice(
                      this.formEditControls.indexOf(row.value),
                      1
                    );
                  }
                });
              }
            }

            if (actionBlock.settings.fileSettings) {
              if (actionBlock.settings.fileSettings.fileCheckList.length) {
                let documentCheckList =
                  actionBlock.settings.fileSettings.fileCheckList;
                documentCheckList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
                this.docWarning = actionBlock.settings.fileSettings.partial;
                // if (documentCheckList.length) {
                //   documentCheckList.forEach((row) => {
                //     if (row.required && !row.attach) {
                //       this.docWarning = true;
                //     }
                //   });
                // }
                // this.$emit("update:checkList", documentCheckList);
                this.checkList = documentCheckList;
              }
            } else {
              //   this.$emit("update:checkList", []);
              this.checkList = [];
            }

            if (actionBlock.settings.workflowInitiator) {
              this.subWorkflowId = actionBlock.settings.subWorkflowId;
            }

            this.displayInternalPublic = false;
            let startBlock = wflowJson.blocks.find(
              (block) => block.type === "START"
            );
            if (startBlock) {
              if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
                this.displayInternalPublic = true;
              }
            }
          }
          if (this.isCustomizedWorkflow) {
            if (
              this.selectedNode === "common" &&
              this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT"
            ) {
              if (this.$store.state.session.groups) {
                let userGroupList = this.$store.state.session.groups;
                if (userGroupList.length) {
                  let assigner = userGroupList.find((group) => group.id === 3);
                  if (assigner) {
                    await this.checkTicketLock();
                  }
                }
              }
            } else {
              await this.checkTicketLock();
            }
          }
        } else {
          this.formEditAccess = true;
        }
        this.initiateProcess = false;
        this.getModel(this.hasSelectedProcess);
        this.actions = this.processActions;
        let origin = location.origin;
        if (
          this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          this.dataValidation(this.hasSelectedProcess, false);
          // this.$emit("validation", this.hasSelectedProcess, false);
        }

        if (
          origin === "https://edmsuat.sobhaapps.com" &&
          this.workflow.id === 55
        ) {
          this.formEditAccess = true;
        }
        this.loadProcessLinks();
      }
      if (this.workflow.wFormId) {
        this.formId = String(this.workflow.wFormId);
        await this.getForm(this.workflow.wFormId);
        // this.form = JSON.parse(this.wform.formJson);
        console.log(this.form);
        if (!initiate) {
          if (this.form.settings.general.layout === "CARD") {
            this.form.settings.general.layout = "TAB";
          }
        }
      }
      this.getTaskForm();
      if (this.selectedProcess.processId) {
        this.getSubWorkflow();
      }

      this.processSheet = true;
    },

    loadProcessLinks() {
      this.getComments();
      this.getAttachments();
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = this.selectedProcess.commentsCount;
        }
      });
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = this.selectedProcess.attachmentCount;
        }
      });
      this.getProcessSignature();
      this.getMailTransactions();
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    toggleWidth() {
      this.formWidth = this.formWidth === "100vw" ? "50vw" : "100vw";
    },

    closeProcessSheet(forceRefresh) {
      if (this.isCustomizedWorkflow) {
        if (
          this.selectedProcess.ticketLockedBy === this.$store.state.session.id
        ) {
          this.releaseReservedRequest(this.selectedProcess);
          // this.$emit("release", this.selectedProcess);
        }
      }
      this.processSheet = false;
      this.saveAction = "";
      this.workflowFormModel = {};
      this.subProcessData = {};
      this.subProcessTickets = [];
      // this.$emit("update:fullView", false);
      if (this.isCustomizedWorkflow && forceRefresh) {
        // this.$emit("refresh", this.workflow.id);
        this.closeRequest();
      }
      if (forceRefresh) {
        this.$emit("update:addRequest", false);
      }
      // this.closeRequest();
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      if (this.$refs.attachments) {
        this.$refs.attachments.value = null;
      }
    },

    async uploadWflowAttachments(e) {
      this.fileUploadForControl = false;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            this.uploadFilename = e.target.files[0].name;
          }
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        if (fields) {
          formData.append("fields", fields);
        } else {
          formData.append("fields", "");
        }
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
          this.documentLoad = setInterval(() => {
            this.getAttachments();
            clearInterval(this.documentLoad);
          }, 15000);
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        if (Object.keys(this.reuploadFile).length) {
          await this.attachmentDelete(this.reuploadFile);
          this.reuploadFile = {};
        }
        //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        } else {
          formData.append("filename", filename);
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.$alert.success(`Document attached`);
          let fileName = this.repositoryField.reduce((max, curren) =>
            max.level > curren.level ? max : curren
          );
          let name = this.fileData.find((item) => item.name === fileName.name);

          let loading = false;
          if (ext.toLowerCase() !== "pdf") {
            loading = true;
            this.documentLoadList.push(Number(filesList.fileId));
            this.documentLoad = setInterval(() => {
              let file = this.attachmentList.find(
                (row) => this.documentLoadList.indexOf(row.id) > -1
              );
              if (file) {
                this.documentLoadList.splice(
                  this.documentLoadList.indexOf(file.id),
                  1
                );
                file.loading = false;
              }
              if (this.documentLoadList.length === 0) {
                clearInterval(this.documentLoad);
              }
            }, 10000);
          }

          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: fields ? name.value + "." + ext : filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
            createdBy: this.$store.state.session.id,
            fileData: this.fileData,
            repositoryId: this.workflow.repositoryId,
            loading: loading,
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;

      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
        let origin = location.origin;
        if (
          this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          if (
            this.selectedProcess.attachmentCount !== this.attachmentList.length
          ) {
            this.formCheckList = true;
          }
        }
      }
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    async resendMail(mailId) {
      if (mailId) {
        const { error } = await workflow.resendMail(mailId);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$alert.success("Resend Successful!");
        this.getMailTransactions();
      } else {
        this.$alert.info("Unable to resend");
      }
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        if (file.initiate) {
          if (this.repositoryDetails.fieldsType === "STATIC") {
            const { error } = await uploadAndIndex.deleteIndexedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          } else {
            const { error } = await uploadAndIndex.deleteUploadedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          }
          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          console.log(hasField);
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          console.log(hasValue);
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
      this.notifyInitiator = false;
    },

    customCheckUpload() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" &&
        (this.workflow.id === 55 || this.workflow.id === 2092)
      ) {
        if (this.attachmentList.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    checkTenant() {
      if (this.isTenantArmgroup()) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        (origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net" ||
          origin === "http://localhost:8080") &&
        this.$store.state.session.tenantId === 3
      ) {
        return true;
      }
      return false;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        if (this.isTenantGOTO() && this.selectedProcess.jiraIssueInfo.id) {
          return true;
        }
      }

      return false;
    },

    checkCommentsOption() {
      if (this.isTenantArmgroup()) {
        return false;
      } else if (this.isTenantGOTO()) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }
        return false;
      }
      return true;
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.fileNames = [];
        this.fileNames = this.attachmentList
          .filter((item) => {
            let extension = item.name.split(".").pop().toUpperCase();

            return extension.toLowerCase() === "pdf";
          })
          .map((item) => {
            return {
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            };
          });
        this.commentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        // this.$emit("history", this.selectedProcess.processId);
        this.showHistory(this.selectedProcess.processId);
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflow.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.addTask();
          // this.$emit("addTask");
        }
      } else if (tab === "VALIDATION") {
        this.dataValidation(this.selectedProcess.processId);
      } else if (tab === "PAYMENT_TABLE") {
        // this.$emit("paymentTable");
        this.showPaymentTable();
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    sublastActionDuration(request) {
      const date =
        request.raisedAt instanceof Date
          ? request.raisedAt
          : new Date(request.raisedAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    validateType(key) {
      let fieldType = "";
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    async uploadFileForControl(files, control, fieldId, aiSettings) {
      this.fileUploadInput = {
        files: files,
        control: control,
        fieldId: fieldId,
        aiSettings: aiSettings,
      };
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.fileUploadForControl = false;

        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            // this.uploadFilename = files.target.files[0].name;
          }
        } else {
          // this.uploadFilename = files.target.files[0].name;
        }
        this.fileUploadForControl = true;
        this.showMetaDataEditModal = true;
      } else {
        this.uploadFilesFromForm(files, control, fieldId, "", aiSettings);
      }
    },

    async getOCRResult(files, control, fieldId, aiSettings) {
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              formData.append("formFields", JSON.stringify(formFields));

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    let hasFile = this.workflowModel[fieldId].findIndex(
                      (item) => {
                        return item.name === file.name;
                      }
                    );
                    // console.log(hasFile);
                    if (hasFile >= 0) {
                      this.$set(
                        this.workflowModel[fieldId][hasFile],
                        "uploadedPercentage",
                        0
                      );
                      if (uploadedPercentage < 50) {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = uploadedPercentage;
                      } else {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = 49;
                      }
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                // console.log(data);

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );
                // console.log(list);

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let fieldId = this.formFields.find(
                      (item) => item.label === hasFile.name
                    );
                    if (fieldId) {
                      if (hasFile.value) {
                        this.workflowModel[fieldId.value] = hasFile.value;
                      }
                    }
                  });
                  this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result`);
              }
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });
            // console.log(hasFile);
            if (hasFile >= 0) {
              //this.workflowModel[fieldId][hasFile].uploadedPercentage = 99;
              // this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFileForControl(files, control, fieldId, aiSettings);
        }
      } else {
        this.uploadFileForControl(files, control, fieldId, aiSettings);
      }
    },

    async uploadFilesFromForm(files, control, fieldId, fields, aiSettings) {
      this.formUploadFiles = [];
      const formData = new FormData();
      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            if (!isEmpty(aiSettings)) {
              if (aiSettings.formControlValidate) {
                if (aiSettings.formControlValidate.optionsType === "MASTER") {
                  formData.append(
                    "masterFormId",
                    aiSettings.formControlValidate.masterFormId
                  );
                  formData.append(
                    "masterFormColumn",
                    aiSettings.formControlValidate.masterFormColumn
                  );
                }
                if (aiSettings.fileDataValidate.length) {
                  formData.append(
                    "isValidateFile",
                    aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                  );
                }
              }
            }

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);
                  let hasFile = this.workflowModel[fieldId].findIndex(
                    (item) => {
                      return item.name === file.name;
                    }
                  );
                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    if (uploadedPercentage > 50 && uploadedPercentage !== 100) {
                      this.workflowModel[fieldId][hasFile].uploadedPercentage =
                        uploadedPercentage;
                    } else {
                      this.workflowModel[fieldId][
                        hasFile
                      ].uploadedPercentage = 98;
                    }
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              file.createdAt = this.$day.newDateTime();
              file.createdBy = this.$store.state.session.email;
              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              file.fileDataValidate = filesList.OCRfieldlist;
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          let hasFile = this.workflowModel[fieldId].findIndex((item) => {
            return item.name === file.name;
          });

          // console.log(hasFile);

          if (hasFile >= 0) {
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 99;
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          }
        }

        let _files = Array.from(files);
        // console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // console.log(file.name);
          if (file.name) {
            const base64String = await fileToBase64(file);

            this.formUploadFiles.push({
              name: file.name,
              size: file.size,
              file: base64String,
              fileId: file.fileId,
              createdBy: file.createdBy,
              createdAt: file.createdAt,
              workflowId: this.selectedProcess.id,
              processId: this.selectedProcess.processId,
              fieldId: file.fieldId,
              fileDataValidate: file.fileDataValidate,
            });
          }
        }
        if (control) {
          if (control.length) {
            control.forEach((con) => {
              let index = this.readonlyControls.findIndex((item) => {
                return item === con;
              });

              if (index >= 0) {
                this.readonlyControls.splice(index, 1);
              }
            });
          }
        }

        this.$store.commit("hideLoadingBarSave");
      }
    },

    mandatoryControlsList(value) {
      this.mandatory = value;
    },

    deletedFiles(ids, files) {
      //this.fileControlDeleteFile = [];
      this.fileControlDeleteFile.push(ids);
      if (files) {
        if (files.controls) {
          if (files.controls.length) {
            files.controls.forEach((field) => {
              console.log(field);
              this.workflowModel[field] = "";
            });
          }
        }

        let hasFile = this.attachmentList.findIndex((file) => {
          return (
            file.name === files.file.name &&
            file.id === files.file.fileId &&
            file.fileControl
          );
        });
        this.ocrResult = [];
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    async fileControlDeletedFile(file) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.workflow.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // this.fileControlDeleteFile = [];
    },

    async getComments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            hasNotifytoInitiated: this.notifyInitiator,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
          this.clearAttach();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    validateRequest(action) {
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      }
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.isTenantGOTO()) {
          if (this.saveAction === "Assign" && this.internalForward.proceed) {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }

        if (this.isTenantArmgroup()) {
          if (this.saveAction === "Assign") {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
      }

      if (addOnCheck) {
        if (this.saveAction === "Save") {
          this.actionConfirm = true;
          this.actionConfirmAlert = true;
          return;
        } else {
          let actionAddOn = this.processActionsAddOn.find(
            (row) => row.action === this.saveAction
          );
          if (actionAddOn) {
            if (Object.keys(actionAddOn).length) {
              this.actionRemarks = actionAddOn.remarks;
              this.actionPassword = actionAddOn.passwordAccess;
              this.actionConfirm = actionAddOn.confirm;
              if (this.actionPassword) {
                this.actionPasswordConfirm = true;
                return;
              }
              if (this.actionRemarks) {
                this.actionComments = true;
                this.advancedCommentsSheet = true;
                return;
              }
              if (this.actionConfirm) {
                this.actionConfirmAlert = true;
                return;
              }
            }
          }
        }
      }

      let inputData = {};
      let origin = location.origin;

      if (this.userReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "UserReply";
      } else if (this.toRequester && this.saveAction === "To Requester") {
        this.saveAction = "ToInitiator";
      }
      if (this.customReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }
      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        }
      });
      if (this.formId) {
        if (this.isTenantGOTO() && this.saveAction === "Rollback") {
          if (this.workflow.id === 1) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          origin === "http://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.workflow.id === 6) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          this.isTenantArmgroup() &&
          this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT" &&
          this.saveAction === "Assign"
        ) {
          let assignToField = this.formFields.find(
            (field) => field.label === "Assigned To"
          );
          if (assignToField) {
            let assignUserEmail = this.userList.find(
              (user) => user.value === this.armgroupForwardUser
            );
            if (assignUserEmail) {
              this.workflowFormModel[assignToField.value] =
                assignUserEmail.label;
              let initialsField = this.formFields.find(
                (field) => field.label === "Initials"
              );
              this.workflowFormModel[initialsField.value] =
                this.userInitials(assignUserEmail);
            } else {
              this.$alert.warning("Select any one user to assign");
              return;
            }
            this.armgroupForwardUser = "";
          }
        }
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review:
            this.saveAction === "Save" || this.saveAction === "CustomVerify"
              ? ""
              : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
            formUpload: formUpload,
          },
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      // console.log(inputData);
      if (!this.saveOnly) {
        this.$store.commit("showLoadingBarSave");
      }
      const { error } = await workflow.processTransaction(inputData);
      if (!this.saveOnly) {
        this.$store.commit("hideLoadingBarSave");
      }

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }
      if (this.saveOnly) {
        this.saveOnly = false;
        //this.$alert.info(`${this.selectedProcess.requestNo} data saved, awaiting your action to submit`);
        //this.$emit("paymentTable");
      } else {
        if (inputData.review === "") {
          this.$alert.info(
            `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
          );
        } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
          this.$alert.danger(
            `${this.selectedProcess.requestNo} Request Rejected Successfully`
          );
        } else {
          this.$alert.success(
            `${this.selectedProcess.requestNo} Request Processed`
          );
        }
        if (inputData.review) {
          this.forwardUsers = [];
          this.forwardGroups = [];
          this.customReply = false;
          this.userReply = false;
          this.toRequester = false;
          this.selectedProcess = {};
          // this.$emit("update:customReply", false);
          // this.$emit("update:userReply", false);
          // this.$emit("update:toRequester", false);
          // this.$emit("update:selectedProcess", {});
          this.closeProcessSheet();
          this.processForwardModal = false;
          // this.$emit("refresh", this.workflow.id);
          this.closeRequest();
        }
      }
      if (this.fileControlDeleteFile.length) {
        this.fileControlDeleteFile.forEach((file) => {
          this.fileControlDeletedFile(file);
        });

        this.fileControlDeleteFile = [];
      }
    },

    async groupProcessRequest() {
      let transactionId = [];
      let mlPrediction = "";
      if (this.isTenantArmgroup()) {
        this.workflowRequestList.forEach((process) => {
          if (process.checked) {
            transactionId.push(process.transactionId);
          }
        });
        let assignToField = this.formFields.find(
          (field) => field.label === "Assigned To"
        );
        if (assignToField) {
          let assignUserEmail = this.userList.find(
            (user) => user.value === this.armgroupForwardUser
          );
          if (assignUserEmail) {
            this.workflowFormModel[assignToField.value] = assignUserEmail.label;
            let initialsField = this.formFields.find(
              (field) => field.label === "Initials"
            );
            this.workflowFormModel[initialsField.value] =
              this.userInitials(assignUserEmail);
          } else {
            this.$alert.warning("Select any one user to assign");
            return;
          }
          this.armgroupForwardUser = "";
        }
      } else {
        this.workflowRequestList.forEach((process) => {
          if (process.key === this.groupActionKey && process.checked) {
            transactionId.push(process.transactionId);
          }
        });
      }

      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      let input = {
        workflowId: this.workflow.id,
        transactionId: transactionId,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        fields: this.workflowFormModel,
        mlPrediction: mlPrediction,
        comments: this.commentText,
        userIds: this.forwardUsers,
        groupIds: this.forwardGroups,
        filterBy: this.filterBy,
      };
      //console.log(input);
      //this.$store.commit("showLoadingBar");
      const { error } = await workflow.groupRequestAction(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.isTenantGOTO()) {
        await this.insertJiraIssueComment();
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      this.forwardUsers = [];
      this.forwardGroups = [];
      this.$alert.success(`Group Request Processed`);
      this.processForwardModal = false;
      this.closeRequestActionModal();
      // this.$emit("refresh", this.workflow.id);
      this.closeRequest();
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }

          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
        } else {
          let domainURL = "";
          let action = "";
          if (
            this.selectedNode === "inbox" ||
            this.selectedNode === "common" ||
            this.selectedNode === "payment"
          ) {
            action = "&action=all";
          }
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              action = "";
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              localStorage.setItem("viewerClose", false);
              this.watchViewerClose = setInterval(
                () => this.checkViewerClose(),
                1000
              );
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            localStorage.setItem("viewerClose", false);
            this.watchViewerClose = setInterval(
              () => this.checkViewerClose(),
              1000
            );
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
        }
      }
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "common" ||
        this.selectedNode === "payment"
      ) {
        let domainURL = "",
          actions = "&action=all";
        if (this.isTenantArmgroup()) {
          domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
        } else if (origin === "https://trial.ezofis.com") {
          domainURL = "https://trial.ezofis.com/docsviewer/index.html";
        } else if (this.isTenantGOTO()) {
          domainURL = "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
        } else if (origin === "http://52.172.32.88") {
          domainURL = `http://52.172.32.88/DocsViewer/index.html`;
        } else {
          domainURL = `${origin}/PDFViewer/web/viewer.html`;
        }
        if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
          this.documentSignProcess = true;
          localStorage.setItem("signItemId", 0);
          localStorage.setItem("viewerClose", false);
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}&signature=true&getSign=true&uniqId=${this.selectedProcess.raisedByUserId}`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}${actions}`;
      } else {
        let domainURL = "",
          actions = "&action=download,print";
        if (this.isTenantArmgroup()) {
          domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          actions = "";
        } else if (origin === "https://trial.ezofis.com") {
          domainURL = "https://trial.ezofis.com/docsviewer/index.html";
        } else if (this.isTenantGOTO()) {
          domainURL = "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
        } else if (origin === "http://52.172.32.88") {
          domainURL = `http://52.172.32.88/DocsViewer/index.html`;
        } else {
          domainURL = `${origin}/PDFViewer/web/viewer.html`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}${actions}`;
      }
    },

    openFileByCmd(file) {
      const originalFile = file.embedFileList[0];
      const { fileName: name, itemid: id, ...rest } = originalFile;
      this.selectedFile = { name, id, ...rest };
      if (this.isTenantArmgroup()) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        if (this.isTenantArmgroup()) {
          window.open(this.fileLink());
        } else {
          this.fileSheet = true;
        }
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          this.fileSheet = false;
          localStorage.removeItem("viewerClose");
          clearInterval(this.watchViewerClose);
          if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
            this.getSignerSettings();
          }
        }
      }
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.workflow.id}/${id}`;
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      if (this.jiraCommentAction === 1) {
        let resourceIDFieldId = this.formFields.find(
          (field) => field.label === "Resource ID"
        )?.value;
        commentsText =
          this.selectedProcess.formData.fields[resourceIDFieldId] +
          "," +
          commentsText;
      }
      commentsText = `${this.$store.state.session.firstName} ${this.$store.state.session.lastName}: ${commentsText}`;
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
      this.jiraCommentAction = 1;
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },

    showHistory(processId) {
      this.selectedProcess = this.workflowRequestList.find(
        (row) => row.processId === processId
      );
      this.historyProcessId = processId;
    },

    closeRequestActionModal() {
      this.requestActionModal = false;
      this.groupActionKey = "";
      this.groupActionFields = [];
      this.groupActionModalButton = {};
    },

    closeJiraIssueSheet() {
      this.issueName = "";
      this.projectKey = "";
      this.issueType = "";
      this.issueDescription = "";
      this.issueEpicValue = "";
      this.issueAssignee = "";
      this.issueDescriptionTable = false;
      this.issueDescriptionFields = [];
      this.jiraIssueSheet = false;
    },

    async createJiraIssue() {
      this.issueRef = {};
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      let transactionId = [];
      let mlPrediction = "";
      let descriptionTable = "";
      let totalSavings = 0;

      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      if (mlPrediction === "") {
        this.workflowRequestList.forEach((process) => {
          if (process.checked) {
            transactionId.push(process.transactionId);
          }
        });

        if (this.issueDescriptionTable) {
          descriptionTable = "||Action Taken (Mandatory)";
          this.issueDescriptionFields.forEach((field) => {
            descriptionTable += "||" + field;
          });
          descriptionTable += "||\n";
          this.workflowRequestList.forEach((process) => {
            if (process.checked) {
              descriptionTable += "|| ";
              this.issueDescriptionFields.forEach((field) => {
                let fieldId = this.formFields.find(
                  (row) => row.label === field
                )?.value;
                if (field === "Savings") {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable +=
                      "||" +
                      Number(process.formData.fields[fieldId]).toFixed(2);
                    totalSavings += Number(process.formData.fields[fieldId]);
                  } else {
                    descriptionTable += "|| ";
                  }
                } else {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable += "||" + process.formData.fields[fieldId];
                  } else {
                    descriptionTable += "|| ";
                  }
                }
              });
              descriptionTable += "||\n";
            }
          });
          descriptionTable +=
            "Potential Savings: " + totalSavings.toFixed(2) + "\n";
        }
      }
      //customfield_12130: this.issueEpicValue,
      let input = {};
      if (this.jiraAccounts[0].email) {
        input = {
          fields_cloud: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: {
                content: [
                  {
                    content: [
                      {
                        text: this.issueDescription + descriptionTable,
                        type: "text",
                      },
                    ],
                    type: "paragraph",
                  },
                ],
                type: "doc",
                version: 1,
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
        };
      } else {
        input = {
          fields_onpremise: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: this.issueDescription + descriptionTable,
              customfield_14635: {
                value: "No",
              },
              customfield_15136: {
                value: "Maintenance",
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
          workflowId: this.workflow.id,
          transactionId: transactionId,
          mlPrediction: mlPrediction,
          assignee: this.issueAssignee ? { name: this.issueAssignee } : {},
          filterBy: this.filterBy,
        };
      }
      //console.log(input);
      this.$store.commit("showLoadingBar");
      const { error, payload } = await integration.createJiraIssue(input);
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.warning("error creating issue in jira");
        let msg = JSON.parse(error);
        console.log(msg);
        this.errorMessage = msg.errorMessages;
        // let msgContent = JSON.parse(msg.Message);
        // this.errorMessage = msgContent.errors;
        this.errorModal = true;
        return;
      }
      if (mlPrediction) {
        this.successModal = true;
      } else {
        if (payload) {
          this.issueRef = JSON.parse(payload);
          this.issueRef.self = this.getJIRALink(this.issueRef);
          this.$alert.success(`Issue Created in JIRA`);
          this.jiraConfirmModal = true;
        } else {
          this.$alert.warning("error creating issue in jira");
          return;
        }
      }
      this.closeJiraIssueSheet();
    },

    async insertJiraIssueComment() {
      let referenceIdFieldId = "",
        remarksFieldId = "";
      referenceIdFieldId = this.formFields.find(
        (field) => field.label === "Reference ID"
      )?.value;
      remarksFieldId = this.formFields.find(
        (field) => field.label === "Remarks"
      )?.value;
      if (
        this.workflowFormModel[referenceIdFieldId] &&
        this.workflowFormModel[remarksFieldId]
      ) {
        this.$store.commit("showLoadingBar");
        let input = {};
        if (this.jiraAccounts[0].email) {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_cloud: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        } else {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_onpremise: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        }

        const { error, payload } = await integration.insertJiraIssueComments(
          input
        );
        this.$store.commit("hideLoadingBar");
        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload);
      }
    },

    async getJiraUsers() {
      this.issueAssigneeUsers = [];
      const { error, payload } = await integration.getJiraUsers({
        cloudIntegrationId: this.jiraAccounts[0].id,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((user) => {
          this.issueAssigneeUsers.push({
            id: user.key,
            label: user.displayName,
            value: user.name,
          });
        });
      }
    },

    closeJiraConfirmModal() {
      this.jiraConfirmModal = false;
      // this.$emit("refresh", this.workflow.id);
      this.closeRequest();
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    submitGroupAction() {
      this.saveAction = this.groupActionModalButton.name;
    },

    checkRequiredDocument() {
      if (this.saveAction === "Save" || this.saveAction === "Force Close") {
        this.proceedSubmission();
      } else {
        if (this.documentRequired && this.attachmentList.length === 0) {
          this.$alert.warning(
            "A minimum of one document is required for submission"
          );
          this.saveAction = "";
          this.workflowFormModel = {};
          return;
        }
        let docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );

        if (docRequired.length) {
          if (this.docWarning) {
            this.alertModal = true;
          } else {
            this.$alert.warning("Mandatory documents required");
            this.saveAction = "";
            this.workflowFormModel = {};
          }
        } else {
          this.proceedSubmission();
        }
      }
    },

    proceedSubmission() {
      this.alertModal = false;
      if (this.initiateProcess) {
        this.initiateRequest();
      } else {
        this.processRequest();
      }
    },

    async initiateRequest() {
      let uploadedIdList = [];
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          uploadedIdList.push(file.id);
        });
      }

      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];

          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });

          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        }
      });

      let inputData = {
        workflowId: this.workflow.id,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        comments: this.commentsList,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
          formUpload: formUpload,
        },
        fileIds: uploadedIdList,
        task: this.taskEntryId,
        hasFormPDF: this.generatePDF,
        prefix: this.requestPrefix,
        mlPrediction: this.mlPrediction,
      };

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$emit("add", {
        workflowId: this.workflow.id,
        requestNo: payload.requestNo,
        processId: payload.processId,
        transactionId: payload.transactionId,
      });
      this.$store.commit("hideLoadingBarSave");
      this.saveAction = "";
      this.workflowFormModel = {};
      this.alertModal = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(payload);
      }

      if (inputData.review) {
        this.$alert.success(`${payload.requestNo} Request Initiated`);
      } else {
        this.$alert.success(
          `${payload.requestNo} Request Initiated and Moved to your inbox`
        );
      }

      if (this.subProcessId) {
        this.addSubWorkflowProcess(payload.processId);
      }

      this.closeProcessSheet();
      // this.$emit("refresh", this.workflow.id);
      this.closeRequest();
    },

    async addSubWorkflowProcess(mainProcessId) {
      let input = {
        mainWorkflowId: this.workflow.id,
        subWorkflowId: this.subWorkflowId,
        mainProcessId: mainProcessId,
        subProcessId: this.subProcessId,
        subProcessStatus: 0,
        review: "",
      };
      const { error } = await workflow.addSubWorkflow(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.updateSubWorkflowProcess();
    },

    async updateSubWorkflowProcess() {
      const { error, payload } = await transaction.updateSubWorkflowProcess(
        this.workflow.id,
        this.$store.state.session.tenantId,
        this.subWorkflowId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
    },

    async dataValidation(processId, modal = true) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await custom.armgroupMLValidation({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.dataValidationValues = payload;
        if (modal) {
          this.dataValidationModal = true;
        }
      } else {
        this.dataValidationValues = [];
        if (modal) {
          this.$alert.info("Data not found");
        }
      }
    },

    async releaseReservedRequest(process) {
      let input = {
        workflowId: process.id,
        processId: process.processId,
        activityId: process.activityId,
        isLock: false,
      };
      const { error } = await workflow.updateRequestReserved(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async showPaymentTable() {
      this.totalPayment = 0;
      this.lumpsumAmount = 0;
      this.benefitType = "";
      this.numberOfApprovePayment = 0;
      this.paymentTableValues = [];
      await this.getPaymentTable();
      this.paymentTableModal = true;
    },

    async getPaymentTable() {
      this.isPaymentFound = false;
      const { error, payload } = await custom.getPaymentTable({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.selectedProcess.processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.paymentTableValues = payload;
        this.isPaymentFound = true;
      }
    },

    async updatePaymentTable(approveAction) {
      this.paymentTableValues.forEach((row) => {
        row.baseAmount = Number(row.baseAmount).toFixed(2);
      });
      let input = {
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        paymentTableList: this.paymentTableValues,
      };
      if (this.isPaymentFound) {
        const { error } = await custom.updatePaymentTable(
          this.selectedProcess.processId,
          input
        );

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await custom.savePaymentTable(input);

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (approveAction) {
        this.requestAction(approveAction, false, false);
      }
    },

    async requestAction(action, forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.isTenantGOTO()) {
          if (action === "Assign" && this.internalForward.proceed) {
            this.processForwardModal = true;
            return;
          }
        }
        if (action === "Forward" && this.internalForward.proceed) {
          this.processForwardModal = true;
          return;
        }

        if (this.isTenantArmgroup()) {
          if (action === "Assign") {
            this.processForwardModal = true;
            return;
          }
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === action
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionText = action;
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionText = action;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionText = action;
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }

      let origin = location.origin;
      if (this.isTenantGOTO() && action === "Rollback") {
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      } else if (
        origin === "http://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.selectedWorkflow.id === 6) {
          this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
        }
      }
      if (this.customReply && action.indexOf("Reply") > -1) {
        action = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }

      if (this.userReply && action.indexOf("Reply") > -1) {
        action = "UserReply";
      } else if (this.toRequester && action === "To Requester") {
        action = "ToInitiator";
      }

      this.selectedProcess.review = action;
      let inputData;
      if (
        (this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)) &&
        this.selectedWorkflow.id === 1 &&
        (action === "Recommend to Approve" || action === "Recommend to Reject")
      ) {
        inputData = {
          workflowId: this.selectedWorkflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: action,
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else {
        inputData = {
          workflowId: this.selectedWorkflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: action,
          formData: this.selectedProcess.formData,
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }

      console.log(inputData);

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
        this.$alert.danger(
          `${this.selectedProcess.requestNo} Request Rejected Successfully`
        );
      } else {
        this.$alert.success(
          `${this.selectedProcess.requestNo} Request Processed`
        );
      }
      this.selectedProcess = {};
      this.processActions = [];
      this.processActionsAddOn = [];
      this.customReply = false;
      this.userReply = false;
      this.toRequester = false;
      this.forwardUsers = [];
      this.fullView = false;
      // this.refresh();
    },

    export_Data() {
      if (this.paymentTableValues.length) {
        let tableData = [];
        tableData = this.paymentTableValues.map((item) => ({
          "Payment Number": item.paymentNumber,
          "Base Amount": item.baseAmount,
          "Claim Amount": item.claimAmount,
          "Cumulative Total": item.cumulativeTotal,
          Approval: item.approval,
          "Approved Date": item.approvedDate,
          "Payment Date": item.paymentDate,
          "Benefit Type": item.benefitType,
        }));
        exportData(tableData);
      }
    },

    paymentApprove() {
      if (this.numberOfApprovePayment) {
        this.paymentTableValues.forEach((row, index) => {
          if (index < this.numberOfApprovePayment) {
            row.approval = "Approved";
            row.approvedDate = this.$day.newDate();
            let dt = new Date();
            dt.setDate(dt.getDate() + 1);
            let month = dt.getMonth() + 1;
            if (dt.getMonth() + 1 < 10) {
              month = "0" + dt.getMonth();
            }
            let day = dt.getDate();
            if (dt.getDate() < 10) {
              day = "0" + dt.getDate();
            }
            row.paymentDate = `${dt.getFullYear()}-${month}-${day}`;
          } else {
            row.approval = "";
            row.approvedDate = "";
            row.paymentDate = "";
          }
        });
      } else {
        this.$alert.warning("Specify the number of payments to approve");
      }
    },

    clearPaymentApprove() {
      this.numberOfApprovePayment = 0;
      this.paymentTableValues.forEach((row) => {
        row.approval = "";
        row.approvedDate = "";
      });
    },

    updateApprovalDate(rowIndex) {
      if (this.paymentTableValues[rowIndex].approval === "Approved") {
        this.paymentTableValues[rowIndex].approvedDate = this.$day.newDate();
        let dt = new Date();
        dt.setDate(dt.getDate() + 1);
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = dt.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        this.paymentTableValues[
          rowIndex
        ].paymentDate = `${dt.getFullYear()}-${month}-${day}`;
      } else {
        this.paymentTableValues[rowIndex].approvedDate = "";
        this.paymentTableValues[rowIndex].paymentDate = "";
      }
    },

    async paymentCalculation() {
      this.numberOfApprovePayment = 0;
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.paymentTableValues = [];
      let total = 0;
      let benefit = 0;
      if (this.benefitType) {
        switch (this.benefitType) {
          case "Monthly":
            benefit = 7;
            break;
          case "BiWeekly":
          case "SemiMonthly":
            benefit = 13;
            break;
          case "Weekly":
            benefit = 25;
            break;
        }
      }
      for (let i = 1; i <= benefit; i++) {
        // let baseAmount = this.totalPayment * 1.2;
        // let claimAmount = baseAmount + baseAmount * 0.2;
        let baseAmount = this.totalPayment;
        let claimAmount = baseAmount + baseAmount * 0.2;
        total += claimAmount;
        if (total + this.lumpsumAmount > 4000) {
          return;
        }
        this.paymentTableValues.push({
          paymentNumber: i,
          baseAmount: baseAmount.toFixed(2),
          claimAmount: claimAmount.toFixed(2),
          cumulativeTotal: total.toFixed(2),
          approval: "",
          approvedDate: "",
          paymentDate: "",
          benefitType: this.benefitType,
        });
      }
    },

    addPaymentRow() {
      const model = {
        paymentNumber: this.paymentTableValues.length + 1,
        baseAmount: "",
        claimAmount: 0,
        cumulativeTotal: 0,
        approval: "",
        approvedDate: "",
        paymentDate: "",
        benefitType: this.benefitType,
        delete: true,
      };

      this.paymentTableValues.push(model);
    },

    removePaymentRow(rowIdx) {
      this.paymentTableValues.splice(rowIdx, 1);
    },

    calcOtherColumns(rowIdx) {
      let total = 0;
      let baseAmount = this.paymentTableValues[rowIdx].baseAmount;
      let claimAmount = baseAmount + baseAmount * 0.2;
      this.paymentTableValues[rowIdx].claimAmount = claimAmount.toFixed(2);
      this.paymentTableValues.forEach((row) => {
        total += Number(row.claimAmount);
      });
      this.paymentTableValues[rowIdx].cumulativeTotal = total.toFixed(2);
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
      // this.taskMode = "ADD";
      // this.showAddTask = !this.showAddTask;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    async insertTaskEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    async getTaskFormEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getFormEntry(formId, entryId);
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];
      let formFields = [];
      for (const panel of panels) {
        formFields.push(...panel.fields);
      }
      let taskOwnerField = formFields.find(
        (field) => field.label === "Task Owner"
      );
      if (taskOwnerField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskOwnerField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      let taskAssignField = formFields.find(
        (field) => field.label === "Task Assign"
      );
      if (taskAssignField) {
        taskFilterBy.push({
          filters: [
            {
              criteria: taskAssignField.id,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
            },
          ],
          groupCondition: "OR",
        });
      }
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getTaskEntries(
        this.selectedWorkflow.id,
        this.selectedProcess.processId,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "createdBy",
                  condition: "IS_EQUALS_TO",
                  value: this.$store.state.session.id,
                },
              ],
            },
            ...taskFilterBy,
          ],
        }
      );
      //this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.taskList = payload;
        payload.forEach((row) => {
          let taskName = "",
            taskAssign = "",
            startDate = "",
            endDate = "",
            priority = "",
            status = "";
          formFields.forEach((field) => {
            switch (field.label) {
              case "Task Name":
                taskName = row.entryInfo[0][field.id];
                break;
              case "Task Assign":
                taskAssign = row.entryInfo[0][field.id];
                break;
              case "Start Date":
                startDate = row.entryInfo[0][field.id];
                break;
              case "End Date":
                endDate = row.entryInfo[0][field.id];
                break;
              case "Priority":
                priority = row.entryInfo[0][field.id];
                break;
              case "Task Status":
                status = row.entryInfo[0][field.id];
                break;
            }
          });
          this.taskEntries.push({
            id: row.entryInfo[0].itemid,
            name: taskName,
            assigned: taskAssign,
            startDate: startDate,
            endDate: endDate,
            priority: priority,
            status: status,
          });
        });
      }
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    subProcessShowLinks(value, process) {
      this.subProcessLink = "";
      this.subProcessData = process;
      this.subProcessLink = value;
    },

    openSubProcess(process) {
      this.subProcessData = process;
      this.openSubProcessSheet = true;
    },

    initiateSubWorkflow() {
      this.initiateSubWorkflowProcess = true;
      //this.closeProcessSheet();
    },

    async getSubWorkflow() {
      this.tabs.find((tab) => {
        if (tab.value === "SUB_PROCESS") {
          tab.count = 0;
        }
      });

      const { error, payload } = await workflow.getSubWorkflow(
        this.workflow.id,
        this.selectedProcess.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.getSubProcessDetails(payload[0].subWorkflowId, payload);
      }
    },

    async getSubProcessDetails(wId, data) {
      this.subProcessTickets = [];
      let filterBy = [];
      data.forEach((row) => {
        filterBy.push({
          filters: [
            {
              criteria: "processId",
              condition: "IS_EQUALS_TO",
              value: row.subProcessId,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "or",
        });
      });
      if (filterBy.length) {
        filterBy[0].groupCondition = "";
      }
      const { error, payload } = await report.getWorkflowData(wId, {
        filterBy: filterBy,
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.meta.totalItems) {
        let process = [];
        data.forEach((sub) => {
          let hasProcess = payload.data[0].value.find(
            (item) => item.processId === Number(sub.subProcessId)
          );
          if (hasProcess) {
            process.push(hasProcess);
          }
        });

        if (process.length) {
          this.subProcessTickets = process;
        }
        this.tabs.find((tab) => {
          if (tab.value === "SUB_PROCESS") {
            tab.count = process.length;
          }
        });
      }
    },

    showSubRequestPreview(item) {
      item.id = item.subWorkFlowId;
      this.hasSelectedProcess = item.processId;
      this.hasSelectedWorkflow = item.subWorkFlowId;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.subWorkFlowId;
      this.openSubProcessSheet = true;
      this.subProcessData = item;
    },

    async openSubAttachments(request) {
      const mainWorkflow = this.workflowRequestList.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          this.$emit("update:selectedProcess", subWorkflow);
          await this.getRepository();
          await this.getAttachments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("ATTACHMENTS");
        }
      }
    },
    async openAttachments(request) {
      let selectedWorkflow = this.workflowList.find(
        (workflow) => workflow.id == request.workflowId
      );
      this.$emit("update:workflow", selectedWorkflow);
      this.$emit("update:selectedProcess", request);
      await this.getRepository();
      await this.getAttachments(request.workflowId, request.processId);
      this.showLinks("ATTACHMENTS");
    },
    async openSubComments(request) {
      const mainWorkflow = this.workflowRequestList.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          // this.$emit("update:selectedProcess", subWorkflow);
          await this.getComments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("COMMENTS");
        }
      }
    },

    async openComments(request) {
      let selectedWorkflow = this.workflowList.find(
        (workflow) => workflow.id == request.workflowId
      );
      this.$emit("update:workflow", selectedWorkflow);
      this.$emit("update:selectedProcess", request);
      await this.getComments(request.workflowId, request.processId);
      this.showLinks("COMMENTS");
    },

    updateSelectedSubWorkflow(item) {
      this.subProcessData = item;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.id;
      this.openSubProcessSheet = true;
    },

    closeAlertModal() {
      this.alertModal = false;
      this.saveAction = "";
      this.workflowFormModel = {};
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      if (this.fileUploadForControl) {
        this.metaDataModal = modal;
        let input = this.fileUploadInput;
        this.uploadFilesFromForm(
          input.files,
          input.control,
          input.fieldId,
          JSON.stringify(data),
          input.aiSettings
        );
      } else {
        this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
      }
    },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.initiateSubWorkflowProcess = false;
      // this.subProcessTickets = [];
    },

    async checkFormValidation() {
      this.hasCheckFormValidate = true;
    },

    getModel(processId) {
      this.formModel = {};
      this.itemModel = {};
      if (Object.keys(this.selectedProcess).length === 0) {
        this.workflowRequestList.forEach((process) => {
          if (
            process.processId == processId &&
            process.id == this.workflow.id
          ) {
            this.headerTitle = `${process.requestNo} - ${process.stage}`;
            if (process.formData) {
              let keys = Object.keys(process.formData.fields);
              let newValues = {};
              keys.forEach((key) => {
                newValues[key] = this.validateTypeValue(
                  process.formData.fields[key]
                );
              });
              this.formModel = newValues;
            } else if (process.itemData) {
              this.itemModel = process.itemData;
            }
          }
        });
      } else {
        let process = this.selectedProcess;
        this.headerTitle = `${process.requestNo} - ${process.stage}`;
        if (process.formData) {
          let keys = Object.keys(process.formData.fields);
          let newValues = {};
          keys.forEach((key) => {
            newValues[key] = this.validateTypeValue(
              process.formData.fields[key]
            );
          });
          this.formModel = newValues;
        } else if (process.itemData) {
          this.itemModel = process.itemData;
        }
      }
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflow.id,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async getProcessSignature() {
      this.signList = [];
      const { error, payload } = await workflow.getProcessSignature(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    updateDataValidationValues(value) {
      this.dataValidationValues = value;
      // this.$emit("update:dataValidationValues", value);
    },

    async showActionButton(processId, workflowId) {
      this.hasSelectedProcess = processId;
      this.hasSelectedWorkflow = workflowId;
      this.processActions = [];
      this.processActionsAddOn = [];
      this.internalForward = {
        proceed: false,
        users: [],
        groups: [],
      };
      this.userSignature = false;
      let process = this.workflowRequestList.filter(
        (req) => req.processId === processId && req.id === workflowId
      );
      this.checkList = [];
      if (process.length) {
        if (!this.myInboxShow) {
          this.selectedProcess = process[0];
        }
        if (
          this.selectedNode === "inbox" ||
          this.selectedNode === "common" ||
          this.selectedNode === "payment"
        ) {
          let wflowJson;
          if (this.myInboxShow) {
            this.form = {};
            this.selectedWorkflow = this.workflowList.find(
              (workflow) => workflow.id == process[0].id
            );
            wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
            if (
              this.selectedWorkflow.wFormId &&
              Object.keys(this.form).length === 0
            ) {
              await this.getForm();
            }
            this.getHideColumns();
            this.selectedProcess = process[0];
          } else {
            wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          }
          this.processActions = [];
          this.processActionsAddOn = [];
          if (
            this.isTenantArmgroup() &&
            this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv1"
          ) {
            wflowJson.rules.forEach((rule) => {
              if (
                rule.fromBlockId === this.selectedProcess.activityId &&
                rule.proceedAction === "Force Close"
              ) {
                this.processActions.push(rule.proceedAction);
                this.processActionsAddOn.push({
                  action: rule.proceedAction,
                  remarks: rule.remarks || false,
                  passwordAccess: rule.passwordAccess || false,
                  confirm: rule.confirm || false,
                });
              }
            });
          } else {
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === this.selectedProcess.activityId) {
                if (
                  this.isTenantArmgroup() &&
                  this.selectedNode === "common" &&
                  this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT"
                ) {
                  if (this.$store.state.session.groups) {
                    let userGroupList = this.$store.state.session.groups;
                    if (userGroupList.length) {
                      let assigner = userGroupList.find(
                        (group) => group.id === 3
                      );
                      if (assigner) {
                        this.processActions.push(rule.proceedAction);
                        this.processActionsAddOn.push({
                          action: rule.proceedAction,
                          remarks: rule.remarks || false,
                          passwordAccess: rule.passwordAccess || false,
                          confirm: rule.confirm || false,
                        });
                      }
                    }
                  }
                } else {
                  this.processActions.push(rule.proceedAction);
                  this.processActionsAddOn.push({
                    action: rule.proceedAction,
                    remarks: rule.remarks || false,
                    passwordAccess: rule.passwordAccess || false,
                    confirm: rule.confirm || false,
                  });
                }
              }
            });
          }

          wflowJson.blocks.forEach((block) => {
            if (block.id === this.selectedProcess.activityId) {
              //console.log(block.settings);
              if (
                this.selectedProcess.lastAction === "Forward" &&
                block.settings.forwardedUserAction === "REPLY"
              ) {
                this.processActions = [];
                this.processActionsAddOn = [];
                this.processActions.push(
                  "Reply to " + this.selectedProcess.transaction_createdByEmail
                );
                this.processActionsAddOn.push({
                  action:
                    "Reply to " +
                    this.selectedProcess.transaction_createdByEmail,
                  remarks: true,
                  passwordAccess: false,
                  confirm: false,
                });
                this.customReply = true;
              } else {
                if (
                  block.settings.userReply &&
                  this.processActions.indexOf("Reply") === -1
                ) {
                  this.processActions.push(
                    "Reply to " + this.selectedProcess.lastActionStageName
                  );
                  this.userReply = true;
                }
                if (
                  block.settings.toInitiator &&
                  this.processActions.indexOf("To Requester") === -1
                ) {
                  this.processActions.push("To Requester");
                  this.toRequester = true;
                }
                if (block.settings.internalForward) {
                  if (this.isTenantGOTO()) {
                    this.processActions.push("Assign");
                  } else {
                    this.processActions.push("Forward");
                  }
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  if (block.settings.internalForwardUser) {
                    if (block.settings.internalForwardUser.length) {
                      block.settings.internalForwardUser.forEach((user) => {
                        let userFilter = this.userList.filter(
                          (row) => row.value == user
                        );
                        if (userFilter.length) {
                          this.internalForward.users.push({
                            id: this.$nano.id(),
                            label: userFilter[0].label,
                            value: user,
                          });
                        }
                      });
                    }
                  }
                  if (block.settings.internalForwardGroup) {
                    if (block.settings.internalForwardGroup.length) {
                      block.settings.internalForwardGroup.forEach((group) => {
                        let groupFilter = this.groupList.filter(
                          (row) => row.value == group
                        );
                        if (groupFilter.length) {
                          this.internalForward.groups.push({
                            id: this.$nano.id(),
                            label: groupFilter[0].label,
                            value: group,
                          });
                        }
                      });
                    }
                  }
                }
              }

              this.userSignature = block.settings.userSignature;
              if (block.settings.fileSettings) {
                if (block.settings.fileSettings.fileCheckList.length) {
                  this.checkList = block.settings.fileSettings.fileCheckList;
                  this.checkList.forEach((row) => {
                    row.uid = this.$nano.id();
                    row.attach = false;
                  });
                }
              }
            }
          });
          if (this.selectedProcess.userType) {
            if (this.selectedProcess.userType === "SUPERUSER") {
              //this.processActions = [];
              this.processActions.push("Force Close");
            }
          }
        } else if (this.selectedNode === "sent") {
          if (this.isTenantGOTO()) {
            if (
              this.selectedProcess.transaction_createdBy ===
              this.$store.state.session.id
            ) {
              this.processActions = [];
              this.processActions.push("Rollback");
            }
          } else if (
            origin === "http://demo.ezofis.com" ||
            origin === "http://52.172.32.88"
          ) {
            if (this.selectedWorkflow.id === 6) {
              if (
                this.selectedProcess.transaction_createdBy ===
                this.$store.state.session.id
              ) {
                this.processActions = [];
                this.processActions.push("Rollback");
              }
            }
          }
        }
        if (
          this.selectedWorkflow.wFormId &&
          Object.keys(this.form).length === 0
        ) {
          await this.getForm();
        }
        this.getTaskList();
      }
    },

    closeRequest() {
      this.$emit("refresh");
      this.$emit("update:addRequest", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  .border-height {
    height: calc(-104px + 100vh);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  #action-btn.linkActions {
    width: 38px !important;
    height: 38px !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList,
#sheet .mailTransactionList,
#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #approvalSignature {
    background-color: var(--component-bg-color);
    border-radius: 4px;

    .title {
      @extend .text-md;
      font-weight: 600;
      text-transform: capitalize;
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
  }
}

#modal {
  #action-btn.copyActions {
    width: 24px !important;
    height: 24px !important;
  }
}

#sheet .jiraIssue {
  .field {
    margin: 16px 16px 16px 16px;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}

#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

.req-no {
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  text-decoration: underline;
  color: var(--title-3-color);
  cursor: pointer;
}

.chip-warning {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chip-success {
  font-weight: 500;
  background-color: #9bf3b0;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#modal #dataValidation,
#modal #paymentTable {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
        &.actions {
          width: 48px;
        }
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}

.advance-cmd {
  margin-right: auto;
}

.file-icon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.text-area-action {
  border: 1px solid var(--border-color);
  #field-wrapper {
    border: none !important;
    .is-focused {
      border: none;
    }
  }
}

.attach-cmd {
  margin-left: 15px;
  #field-wrapper {
    height: 30px !important;
    .select {
      .value {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value:hover {
        max-width: none !important;
        overflow: visible;
        white-space: pre;
      }
    }
  }
}

.select-action {
  padding-top: 10px;

  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loading {
  animation: spin 2s linear infinite;
}

// .q-message-name--received {
//   margin-left: 20px;
// }
// .q-message-name--sent {
//   margin-right: 23px;
// }

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
