<script>
import CardQuickActions from "./CardQuickActions.vue";

export default {
  components: {
    CardQuickActions,
  },
  props: {
    createdBy: {
      type: String,
      default: "",
    },
    createdAt: {
      type: String,
      default: "",
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: [Number, undefined],
      default: undefined,
    },
  },
};
</script>

<template>
  <div id="card-right" :class="{ deleted: isDeleted }">
    <div>
      <div v-if="!hideAvatar" class="created-by">You</div>
      <div class="message">
        <slot></slot>
        <CardQuickActions v-if="!isDeleted" @delete="$parent.$emit('delete')" />
      </div>
      <div class="created-at">{{ $day.formatTime(createdAt) }}</div>
    </div>

    <div v-if="hideAvatar" class="avatar-placeholder"></div>
    <div v-else class="avatar">
      <BaseIcon name="mdi-account" inherit-color />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#card-right {
  display: flex;
  gap: 8px;
  margin-left: auto;
  max-width: 496px;
  word-break: break-word;

  .avatar-placeholder {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
  }

  .avatar {
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;

    &.hide {
      opacity: 0;
    }
  }

  .created-by {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: right;
  }

  .message {
    padding: 12px 16px;
    background-color: var(--component-bg-color);
    border-radius: 16px 0px 16px 16px;
    display: flex;
    gap: 8px;
  }

  &.deleted .message {
    opacity: 70%;
    pointer-events: none;
  }

  .created-at {
    font-size: 10px;
    color: var(--body-text-color-muted);
    margin-top: 4px;
    margin-left: 8px;
    text-align: right;
  }
}

.theme-light {
  .avatar {
    background-color: rgba($color: $secondary-5, $alpha: 0.2);
    color: $secondary-5;
  }

  .created-by {
    color: $secondary-5;
  }
}

.theme-dark {
  .avatar {
    background-color: rgba($color: $secondary-4, $alpha: 0.2);
    color: $secondary-4;
  }

  .created-by {
    color: $secondary-4;
  }
}
</style>
