<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "TaskActions",

  components: { ListItem },

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      actions: [
        {
          label: "Edit",
          value: "edit",
        },
        {
          label: "Copy",
          value: "copy",
        },
        {
          label: "Forward",
          value: "forward",
        },
        {
          label: "Mark as complete",
          value: "markAsComplete",
        },
      ],
      showEmailFileSheet: false,
    };
  },

  methods: {
    applyAction(action) {
      console.log("🚀 ~ applyAction ~ action:", action);
    },
  },
};
</script>

<template>
  <BaseActionButton is-flat icon="eva-more-vertical" no-border>
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom right"
      self="top right"
      auto-close
    >
      <ListItem
        v-for="action in actions"
        :key="action.value"
        :label="action.label"
        @click="applyAction(action.value)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<style lang="scss" scoped></style>
