<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  components: { ListItem },

  data() {
    return {
      items: [
        {
          id: this.$nano.id(),
          label: "Task",
          value: "Task",
          icon: "eva-cube-outline",
        },
        {
          id: this.$nano.id(),
          label: "Request",
          value: "Workflow",
          icon: "o_account_tree",
        },
      ],
    };
  },
};
</script>

<template>
  <BaseActionButton is-flat icon="eva-plus" no-border>
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="top left"
      self="bottom left"
      :offset="[0, 12]"
      auto-close
    >
      <BaseScrollbar height="196px" width="180px" class="q-py-sm">
        <ListItem
          v-for="item in items"
          :key="item.id"
          :label="item.label"
          :icon="item.icon"
          @click="$emit('add', item.value)"
        />
      </BaseScrollbar>
    </q-menu>
  </BaseActionButton>
</template>

<style lang="scss" scoped></style>
