<!-- eslint-disable vue/no-v-html -->
<script>
import CardLeft from "./CardLeft.vue";
import CardRight from "./CardRight.vue";

export default {
  components: { CardLeft, CardRight },

  props: {
    chatItem: {
      type: Object,
      default: () => {},
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    isCurrentUser() {
      return this.chatItem.createdBy === this.$store.state.session.email;
    },
  },

  methods: {
    getMessageLength(message) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, "text/html");
      return doc.body.textContent.length;
    },
  },
};
</script>

<template>
  <component
    :is="isCurrentUser ? 'CardRight' : 'CardLeft'"
    :created-by="chatItem.createdBy"
    :created-at="chatItem.createdAt"
    :hide-avatar="hideAvatar"
  >
    <!-- <div :style="{ whiteSpace: 'pre-line' }" v-html="chatItem.message" /> -->

    <div class="">
      <div
        class="message-box"
        :class="
          getMessageLength(chatItem.message) > 100 && !isExpanded
            ? 'shrink'
            : 'expand'
        "
        v-html="chatItem.message"
      ></div>
      <div
        v-if="getMessageLength(chatItem.message) > 100"
        class="read-more"
        @click="isExpanded = !isExpanded"
      >
        {{ isExpanded ? "Read less" : "Read more" }}
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
.message-box {
  white-space: "pre-line";

  &.shrink {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  &.expand {
    padding-bottom: 8px;
  }
}

.read-more {
  font-size: 12px;
  text-decoration: underline;
  color: var(--icon-color);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    color: var(--secondary);
  }
}
</style>
