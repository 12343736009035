import { render, staticRenderFns } from "./WorkspaceList.vue?vue&type=template&id=305401ed&scoped=true&"
import script from "./WorkspaceList.vue?vue&type=script&lang=js&"
export * from "./WorkspaceList.vue?vue&type=script&lang=js&"
import style0 from "./WorkspaceList.vue?vue&type=style&index=0&id=305401ed&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305401ed",
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QSpinner});
