<script>
import CardLeft from "./CardLeft.vue";
import CardRight from "./CardRight.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";

export default {
  components: { CardLeft, CardRight, FileIcon },

  props: {
    chatItem: {
      type: Object,
      default: () => {},
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isCurrentUser() {
      return this.chatItem.createdBy === this.$store.state.session.email;
    },

    fileSize() {
      return formatBytes(this.chatItem.file.size);
    },

    getStatusColor() {
      if (this.chatItem.file.status === "Success") {
        return this.darkTheme ? "text-green-5" : "text-green-6";
      }

      if (this.chatItem.file.status === "Error") {
        return this.darkTheme ? "text-red-5" : "text-red-6";
      }

      return this.darkTheme ? "text-secondary-6" : "text-secondary-5";
    },
  },
};
</script>

<template>
  <component
    :is="isCurrentUser ? 'CardRight' : 'CardLeft'"
    :created-by="chatItem.createdBy"
    :created-at="chatItem.createdAt"
    :hide-avatar="hideAvatar"
  >
    <div id="file-card">
      <div class="icon">
        <FileIcon :mime-type="chatItem.file.type" />
      </div>

      <div class="content">
        <div class="name">{{ chatItem.file.name }}</div>
        <div class="meta">
          <div class="wrapper">
            <div class="data">Size:</div>
            <div class="data">{{ fileSize }}</div>
          </div>

          <div class="wrapper">
            <div class="data">Status:</div>
            <div class="status" :class="getStatusColor">
              {{ chatItem.file.status }}
            </div>
          </div>

          <q-spinner
            v-if="chatItem.file.status === 'Uploading'"
            color="secondary"
            size="20px"
          />
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
#file-card {
  display: flex;
  gap: 16px;

  .icon {
    width: 32px;
    height: 32px;
  }

  .content {
    flex: 1;
    text-align: left;

    .name {
      font-weight: 600;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--divider-color);
    }

    .meta {
      display: flex;
      align-items: center;
      gap: 16px;

      .status {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--icon-color-inverted);

      .data {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  .success-icon {
    color: var(--green);
  }

  .error-icon {
    color: var(--red);
  }
}
</style>
